import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { useToast } from "~/components/ui/use-toast";
import { VariantCategory } from "../types";
import useSWR from 'swr';
import { getFetcher } from '~/lib/apiClient';
import apiClient from '~/lib/apiClient';
import { PlusCircle, Trash2, Edit, Settings } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import CategoryEditor from './CategoryEditor';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/components/ui/alert-dialog";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCategoryUpdated?: () => void;
};

export default function CategoryManager({ 
  open, 
  onOpenChange, 
  onCategoryUpdated
}: Props) {
  const { toast } = useToast();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [categoryToDelete, setCategoryToDelete] = useState<VariantCategory | null>(null);

  // Fetch categories
  const { data: categoriesResponse, mutate: refreshCategories } = useSWR<{data: VariantCategory[] }>(
    '/store/variant_categories',
    getFetcher
  );
  
  const categories = categoriesResponse?.data || [];

  const handleCreateCategory = () => {
    setSelectedCategoryId(null);
    setIsEditorOpen(true);
  };

  const handleEditCategory = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    setIsEditorOpen(true);
  };

  const handleDeleteCategory = (category: VariantCategory) => {
    setCategoryToDelete(category);
    setIsDeleteDialogOpen(true);
  };

  const confirmDeleteCategory = async () => {
    if (!categoryToDelete) return;

    try {
      await apiClient.delete(`/store/variant_categories/${categoryToDelete.id}`);
      
      // Refresh the categories list
      await refreshCategories();
      
      toast({
        title: "Success",
        description: "Category deleted successfully"
      });

      // Call the onCategoryUpdated callback if provided
      if (onCategoryUpdated) {
        onCategoryUpdated();
      }
    } catch (error: any) {
      console.error('Error deleting category:', error);
      
      // Handle validation errors
      if (error.response?.status === 422 && error.response?.data?.errors) {
        const validationErrors = error.response.data.errors;
        
        // Display the first validation error
        const firstError = Object.values(validationErrors)[0];
        if (Array.isArray(firstError) && firstError.length > 0) {
          toast({
            title: "Error",
            description: firstError[0],
            variant: "destructive"
          });
        } else {
          toast({
            title: "Error",
            description: "This category cannot be deleted",
            variant: "destructive"
          });
        }
      } else {
        toast({
          title: "Error",
          description: "Failed to delete category",
          variant: "destructive"
        });
      }
    } finally {
      setIsDeleteDialogOpen(false);
      setCategoryToDelete(null);
    }
  };

  const handleCategoryUpdated = () => {
    // Refresh the categories list
    refreshCategories();
    
    // Call the onCategoryUpdated callback if provided
    if (onCategoryUpdated) {
      onCategoryUpdated();
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[700px]">
          <DialogHeader>
            <DialogTitle className="flex items-center">
              <Settings className="mr-2 h-5 w-5" />
              Manage Variant Categories
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="flex justify-between items-center mb-4">
              <p className="text-sm text-gray-500">
                Create and manage categories for product variants. Categories define the properties that can be set for each variant.
              </p>
              <Button onClick={handleCreateCategory}>
                <PlusCircle className="h-4 w-4 mr-1" />
                New Category
              </Button>
            </div>
            
            {categories.length === 0 ? (
              <div className="text-center p-6 border border-dashed rounded-md">
                <p className="text-gray-500">No categories available.</p>
                <p className="text-sm text-gray-400 mt-1">Create a category to define properties for your variants.</p>
              </div>
            ) : (
              <div className="space-y-3">
                {categories.map((category) => (
                  <Card key={category.id} className="p-0">
                    <CardContent className="p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-medium text-base">{category.name}</h3>
                          <div className="mt-1 text-sm text-gray-500">
                            {category.fields && category.fields.length > 0 ? (
                              <div>
                                <p className="mb-1">{category.fields.length} field{category.fields.length !== 1 ? 's' : ''}:</p>
                                <ul className="list-disc pl-5 space-y-1">
                                  {category.fields.map(field => (
                                    field ? (
                                      <li key={field.id}>
                                        {field.name} 
                                        <span className="text-xs ml-1 text-gray-400">
                                          ({field.type}{field.required ? ', required' : ''})
                                        </span>
                                      </li>
                                    ) : null
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              <p>No fields defined</p>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          <Button 
                            variant="outline" 
                            size="sm"
                            onClick={() => handleEditCategory(category.id)}
                          >
                            <Edit className="h-4 w-4 mr-1" />
                            Edit
                          </Button>
                          <Button 
                            variant="destructive" 
                            size="sm"
                            onClick={() => handleDeleteCategory(category)}
                          >
                            <Trash2 className="h-4 w-4 mr-1" />
                            Delete
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
          </div>
          <DialogFooter>
            <Button type="button" onClick={() => onOpenChange(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Category Editor Dialog */}
      <CategoryEditor
        open={isEditorOpen}
        onOpenChange={setIsEditorOpen}
        categoryId={selectedCategoryId || undefined}
        onSuccess={handleCategoryUpdated}
      />

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the category "{categoryToDelete?.name}". 
              This action cannot be undone, and may affect existing variants that use this category.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDeleteCategory} className="bg-red-600 hover:bg-red-700">
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
} 