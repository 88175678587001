import { useState } from "react";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Webhook } from "lucide-react";
import apiClient from "~/lib/apiClient";

type WebhookTestResponse = {
  success: boolean;
  message: string;
  request?: {
    url: string;
    method: string;
    headers: Record<string, string>;
    body: any;
  };
  response?: {
    status: number;
    headers: Record<string, string>;
    body: any;
  };
};

interface WebhookTestDialogProps {
  offerId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function WebhookTestDialog({ offerId, isOpen, onClose }: WebhookTestDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<WebhookTestResponse | null>(null);

  const handleTest = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.post<WebhookTestResponse>(
        `/store/offers/${offerId}/test-webhook`
      );
      setResult(response.data);
      if (response.data.success) {
        toast.success("Webhook test completed successfully");
      } else {
        toast.error("Webhook test failed");
      }
    } catch (error) {
      toast.error("Failed to test webhook");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Test Offer Webhook</DialogTitle>
        </DialogHeader>
        
        <div className="mt-4">
          {!result ? (
            <div className="text-center py-6">
              <Webhook className="w-12 h-12 mx-auto text-muted-foreground mb-4" />
              <p className="text-sm text-muted-foreground mb-6">
                This will send a test webhook to your configured endpoint. The response will be displayed here.
              </p>
              <Button 
                onClick={handleTest} 
                disabled={isLoading}
              >
                {isLoading ? "Testing..." : "Send Test Webhook"}
              </Button>
            </div>
          ) : (
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium mb-2">Request</h4>
                <pre className="bg-muted p-3 rounded-md text-sm overflow-auto max-h-40">
                  {JSON.stringify(result.request, null, 2)}
                </pre>
              </div>
              
              {result.response && (
                <div>
                  <h4 className="text-sm font-medium mb-2">Response</h4>
                  <div className="flex items-center gap-2 mb-2">
                    <div className={`px-2 py-1 text-xs rounded-full ${
                      result.response.status < 300 
                        ? "bg-green-100 text-green-700" 
                        : "bg-red-100 text-red-700"
                    }`}>
                      {result.response.status}
                    </div>
                  </div>
                  <pre className="bg-muted p-3 rounded-md text-sm overflow-auto max-h-40">
                    {JSON.stringify(result.response, null, 2)}
                  </pre>
                </div>
              )}

              <DialogFooter>
                <Button onClick={handleTest} disabled={isLoading}>
                  {isLoading ? "Testing..." : "Test Again"}
                </Button>
                <Button variant="outline" onClick={onClose}>
                  Close
                </Button>
              </DialogFooter>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
} 