import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { get } from "lodash";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { H1 } from "~/components/ui/typography";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";

const DEFAULT_THEME = {
  border_radius: 'sm',
  page_bg_color: '',
  button_bg: '',
  button_text_color: '',
  link_color: '',
  base_font_size: '',
  base_text_color: '',
  paragraph_line_height: '',
  font_family: '',
  vertical_spacing: '',
  input_size: '2',
  input_variant: 'surface',
  input_color: '',
  accent_color: '',
}

function only(theme, strings: string[]) {
  const obj = {}
  strings.forEach(key => obj[key] = theme[key])
  return obj
}

function ThemeEditor({ theme, mutate }) {
  const [data, setData] = useState(only(theme, Object.keys(DEFAULT_THEME)));
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setErrors(null);
    setIsSaving(true);

    apiClient.put(`/convert/themes/${theme.id}`, data)
      .then(() => {
        mutate();
        toast.success('Theme updated');
      })
      .catch(err => {
        setErrors(err.response.data.errors || err.response.data.message);
      })
      .finally(() => setIsSaving(false))
  }

  return (
    <div className="p-10 space-y-6">
      <H1>Theme</H1>

      <form onSubmit={handleSubmit} className="space-y-4 max-w-lg">
        <div className="space-y-2">
          <label className="text-sm font-medium">Border Radius</label>
          <Select
            value={data.border_radius}
            onValueChange={(value) => setData({ ...data, border_radius: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select border radius" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="none">None</SelectItem>
              <SelectItem value="sm">Small</SelectItem>
              <SelectItem value="md">Medium</SelectItem>
              <SelectItem value="lg">Large</SelectItem>
              <SelectItem value="xl">Extra Large</SelectItem>
              <SelectItem value="full">Full</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Page Background Color</label>
          <Input
            type="color"
            value={data.page_bg_color}
            onChange={(e) => setData({ ...data, page_bg_color: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Button Background</label>
          <Input
            value={data.button_bg}
            onChange={(e) => setData({ ...data, button_bg: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Button Text Color</label>
          <Input
            type="color"
            value={data.button_text_color}
            onChange={(e) => setData({ ...data, button_text_color: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Link Color</label>
          <Input
            type="color"
            value={data.link_color}
            onChange={(e) => setData({ ...data, link_color: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Base Font Size</label>
          <Input
            type="number"
            value={data.base_font_size}
            onChange={(e) => setData({ ...data, base_font_size: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Base Text Color</label>
          <Input
            type="color"
            value={data.base_text_color}
            onChange={(e) => setData({ ...data, base_text_color: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Paragraph Line Height</label>
          <Input
            type="number"
            value={data.paragraph_line_height}
            onChange={(e) => setData({ ...data, paragraph_line_height: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Font Family</label>
          <Input
            value={data.font_family}
            onChange={(e) => setData({ ...data, font_family: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Vertical Spacing</label>
          <Select
            value={data.vertical_spacing}
            onValueChange={(value) => setData({ ...data, vertical_spacing: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select vertical spacing" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="sm">Small</SelectItem>
              <SelectItem value="md">Medium</SelectItem>
              <SelectItem value="lg">Large</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Input Size</label>
          <Select
            value={data.input_size}
            onValueChange={(value) => setData({ ...data, input_size: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select input size" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">Small</SelectItem>
              <SelectItem value="2">Medium</SelectItem>
              <SelectItem value="3">Large</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Input Variant</label>
          <Select
            value={data.input_variant}
            onValueChange={(value) => setData({ ...data, input_variant: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select input variant" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="surface">Surface</SelectItem>
              <SelectItem value="classic">Classic</SelectItem>
              <SelectItem value="soft">Soft</SelectItem>
              <SelectItem value="stripe">Stripe</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Input Color</label>
          <Input
            type="color"
            value={data.input_color}
            onChange={(e) => setData({ ...data, input_color: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Accent Color</label>
          <Input
            type="color"
            value={data.accent_color}
            onChange={(e) => setData({ ...data, accent_color: e.target.value })}
          />
        </div>

        <Button type="submit" disabled={isSaving}>
          {isSaving ? 'Updating...' : 'Update'}
        </Button>

        {errors && (
          <div className="text-sm text-destructive">
            {typeof errors === 'string' ? errors : Object.entries(errors).map(([key, value]) => (
              <p key={key}>{value[0]}</p>
            ))}
          </div>
        )}
      </form>
    </div>
  )
}

export default function ThemeEditPage() {
  const { data: themes, error, mutate } = useSWR('/convert/themes', getFetcher);
  const theme = get(themes, 'data.0');

  return (
    <div>
      {theme && (
        <ThemeEditor theme={theme} mutate={mutate} />
      )}
    </div>
  )
}
