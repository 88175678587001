import { Button } from "~/components/ui/button";
import { Link } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "~/components/ui/dialog";
import * as Form from "@radix-ui/react-form";
import { useState } from "react";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { toast } from "react-toastify";
import { Offer } from "./types";
import useSWR from "swr";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "~/components/ui/badge";
import { CalendarCheck, ChevronLeft, ChevronRight, ShoppingBag } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";

type NewOfferFormData = {
  name: string;
  checkout_intent: string;
  provisioning_action: string;
};

type PaginatedResponse<T> = {
  data: T[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
};

const INTENT_OPTIONS = [
  { id: 'order', name: 'One-time Order' },
  { id: 'subscription', name: 'Subscription' },
];

export default function OffersListPage() {
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState<NewOfferFormData>({
    name: "",
    checkout_intent: "",
  });
  const [errors, setErrors] = useState<Record<string, [string]>>({});
  const [generalError, setGeneralError] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, error, isLoading, mutate } = useSWR<PaginatedResponse<Offer>>(
    `/store/offers?page=${page}`,
    getFetcher
  );

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setErrors({});
    setGeneralError("");
    setIsSubmitting(true);

    try {
      const response = await apiClient.post("/store/offers", formData);
      toast.success("Offer created successfully!");
      setIsOpen(false);
      setFormData({ name: "", checkout_intent: "" });
      mutate(); // Refresh the offers list
      window.location.href = `/store/offers/${response.data.id}`;
    } catch (error: any) {
      if (error.response?.status === 422 && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setGeneralError("An error occurred while creating the offer. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const { data: statsData } = useSWR<{
    purchases_last_30_days: number;
    net_revenue_last_30_days: number;
    revenue_all_time: number;
  }>(`/store/offers/stats`, getFetcher);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && (!data?.meta.last_page || newPage <= data.meta.last_page)) {
      setPage(newPage);
    }
  };

  return (
    <div className="flex flex-col gap-4 p-10">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Offers</h1>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger asChild>
            <Button>New Offer</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New Offer</DialogTitle>
            </DialogHeader>
            <Form.Root onSubmit={handleSubmit} className="space-y-4">
              {generalError && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="text-sm text-red-700">
                    {generalError}
                  </div>
                </div>
              )}
              <div className="grid gap-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  disabled={isSubmitting}
                  required
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  aria-invalid={errors.name ? "true" : undefined}
                />
                {errors.name && (
                  <p className="text-sm text-destructive">{errors.name}</p>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="checkout_intent">Checkout Intent</Label>
                <Select
                  name="checkout_intent"
                  value={formData.checkout_intent}
                  onValueChange={(value) => setFormData(prev => ({ ...prev, checkout_intent: value }))}
                  disabled={isSubmitting}
                >
                  <SelectTrigger id="checkout_intent">
                    <SelectValue placeholder="Select checkout intent" />
                  </SelectTrigger>
                  <SelectContent>
                    {INTENT_OPTIONS.map((option) => (
                      <SelectItem key={option.id} value={option.id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.checkout_intent && (
                  <p className="text-sm text-destructive">{errors.checkout_intent}</p>
                )}
              </div>
              <div className="flex justify-end gap-2">
                <Button 
                  variant="outline" 
                  onClick={() => setIsOpen(false)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Creating..." : "Create Offer"}
                </Button>
              </div>
            </Form.Root>
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid grid-cols-3 rounded-xl border bg-card text-card-foreground shadow divide-x divide-gray-200">
        <div className="">
          <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="tracking-tight text-sm font-medium">Purchases</div>
          </div>
          <div className="p-6 pt-0">
            <div className="text-3xl font-bold">{statsData?.purchases_last_30_days}</div>
            <p className="text-xs text-muted-foreground">Last 30 days</p>
          </div>
        </div>

        <div className="">
          <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="tracking-tight text-sm font-medium">Net Revenue</div>
          </div>
          <div className="p-6 pt-0">
            <div className="text-3xl font-bold">{statsData?.net_revenue_last_30_days}</div>
            <p className="text-xs text-muted-foreground">Last 30 days</p>
          </div>
        </div>

        <div className="">
          <div className="p-6 flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="tracking-tight text-sm font-medium">Purchase</div>
          </div>
          <div className="p-6 pt-0">
            <div className="text-3xl font-bold">{statsData?.revenue_all_time}</div>
            <p className="text-xs text-muted-foreground">All Time</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Offer Title</TableHead>
              <TableHead>Intent</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Last Updated</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading ? (
              Array.from({ length: 5 }).map((_, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className="h-4 w-48 bg-gray-200 rounded animate-pulse"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                  </TableCell>
                </TableRow>
              ))
            ) : error ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-8 text-gray-500">
                  Failed to load offers. Please try again.
                </TableCell>
              </TableRow>
            ) : data?.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-8 text-gray-500">
                  No offers found. Create your first offer to get started.
                </TableCell>
              </TableRow>
            ) : (
              data?.data.map((offer) => (
                <TableRow key={offer.id}>
                  <TableCell>
                    <Link 
                      to={`/store/offers/${offer.id}`}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      {offer.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2 text-gray-800">
                      {offer.checkout_intent === 'subscription' && <CalendarCheck className="w-4 h-4" />}
                      {offer.checkout_intent === 'order' && <ShoppingBag className="w-4 h-4" />}
                      {offer.checkout_intent === 'subscription' ? 'Subscription' : 'Order'}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge variant={offer.status === 'published' ? 'outline' : 'secondary'}>
                      {offer.status}
                    </Badge>
                  </TableCell>
                  <TableCell className="text-gray-600">
                    {formatDistanceToNow(new Date(offer.created_at), { addSuffix: true })}
                  </TableCell>
                  <TableCell className="text-gray-600">
                    {formatDistanceToNow(new Date(offer.updated_at), { addSuffix: true })}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {data?.meta && (
          <div className="flex items-center justify-between px-4 py-3 border-t border-gray-200">
            <div className="text-sm text-gray-700">
              Showing {data.meta.from} to {data.meta.to} of {data.meta.total} offers
            </div>
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
                size="icon"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button 
                variant="outline"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === data.meta.last_page}
                size="icon"
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}