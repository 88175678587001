import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { useAuth } from "~/hooks/useAuth";
import { H1 } from "~/components/ui/typography";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Loader2, Users, Copy } from "lucide-react";
import { cn } from "~/lib/utils";
import type { Organization, User } from "~/types";

interface Membership {
  id: string;
  email: string;
  name?: string;
  pivot: {
    role: 'owner' | 'admin' | 'member';
  };
}

interface MembershipResponse {
  data: Membership[];
}

function RoleBadge({ role }: { role: string }) {
  switch (role.toLowerCase()) {
    case 'owner':
      return <Badge>Owner</Badge>;
    case 'admin':
      return <Badge variant="secondary">Admin</Badge>;
    default:
      return <Badge variant="outline">Member</Badge>;
  }
}

export default function TeamListPage() {
  const { user } = useAuth();
  const { data: memberships, isLoading } = useSWR<MembershipResponse>(
    user?.current_organization ? `/mgmt/organizations/${user.current_organization.id}/memberships` : null,
    getFetcher
  );

  const copyInviteCode = () => {
    if (user?.current_organization.invite_code) {
      navigator.clipboard.writeText(user.current_organization.invite_code);
    }
  };

  return (
    <div className="p-10 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <H1>Team</H1>
          <p className="text-sm text-muted-foreground">
            Manage your team members and roles
          </p>
        </div>
        <Button variant="outline" onClick={copyInviteCode}>
          <Copy className="h-4 w-4 mr-2" />
          Copy Invite Code
        </Button>
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <h3 className="text-sm font-medium">Organization Invite Code</h3>
          <div className="flex gap-2">
            <Input 
              value={user?.current_organization.invite_code || ''} 
              readOnly 
              className="font-mono bg-muted"
            />
          </div>
        </div>

        {isLoading && (
          <div className="flex items-center justify-center p-6">
            <Loader2 className="h-6 w-6 animate-spin" />
          </div>
        )}

        {!memberships?.data.length && !isLoading && (
          <div className="flex flex-col items-center justify-center py-12 text-center">
            <Users className="h-12 w-12 text-muted-foreground mb-4" />
            <h3 className="text-lg font-semibold">No Team Members</h3>
            <p className="text-sm text-muted-foreground">
              Share your invite code to add members to your team.
            </p>
          </div>
        )}

        {memberships?.data.length > 0 && (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Member</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Role</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {memberships.data.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell className="font-medium">
                      {member.name || member.email.split('@')[0]}
                    </TableCell>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>
                      <RoleBadge role={member.pivot.role} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
}
