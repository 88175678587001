import { Link, useParams, useNavigate, useBeforeUnload } from "react-router-dom";
import { useState, useEffect, useCallback, createContext, useContext } from "react";
import { isEqual, cloneDeep } from 'lodash';
import { toast } from "react-toastify";
import apiClient, { getFetcher } from "~/lib/apiClient";
import { Offer, OfferItem } from "./types";
import { Product, Plan } from "~/types/catalog";
import DetailsTab from "./components/DetailsTab";
import PricingTab from "./components/PricingTab";
import SettingsTab from "./components/SettingsTab";
import UpsellsTab from "./components/UpsellsTab";
import TransactionsTab from "./components/TransactionsTab";
import useSWR from "swr";
import { MessageCircleWarningIcon } from "lucide-react";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "~/components/ui/tabs";
import { Button } from "~/components/ui/button";
const INTENT_OPTIONS = [
  { id: 'order' as const, name: 'One-time Order' },
  { id: 'subscription' as const, name: 'Subscription' },
];

const STATUS_OPTIONS = [
  { id: 'draft' as const, name: 'Draft' },
  { id: 'published' as const, name: 'Published' }
];

interface OfferContextType {
  offer: Offer | undefined;
  products: Product[] | undefined;
  plans: Plan[] | undefined;
  isLoading: boolean;
  error: any;
  mutate: () => void;
  updateOffer: (updates: Partial<Offer>) => void;
}

const OfferContext = createContext<OfferContextType | undefined>(undefined);

export function OfferProvider({ children, offerId }: { children: React.ReactNode; offerId: string }) {
  const { data: productsResponse, error: productsError } = useSWR<{ data: Product[] }>('/catalog/products', getFetcher);
  const { data: plansResponse, error: plansError } = useSWR<{ data: Plan[] }>('/pricing/plans', getFetcher);
  
  const { data: offerResponse, error: offerError, mutate } = useSWR<Offer>(
    offerId ? `/store/offers/${offerId}` : null,
    getFetcher
  );

  // Extract data from paginated responses
  const products = productsResponse?.data;
  const plans = plansResponse?.data;
  const offer = offerResponse;

  const [localOffer, setLocalOffer] = useState<Offer | undefined>(offer);

  useEffect(() => {
    if (offer) {
      setLocalOffer(offer);
    }
  }, [offer]);

  const updateOffer = useCallback((updates: Partial<Offer>) => {
    setLocalOffer(prev => prev ? {
      ...prev,
      ...updates,
      updated_at: new Date().toISOString()
    } : undefined);
  }, []);

  const isLoading = Boolean((!productsResponse && !productsError) || (!plansResponse && !plansError) || (!offerResponse && !offerError && offerId));
  const error = productsError || plansError || offerError;

  const value: OfferContextType = {
    offer: localOffer,
    products,
    plans,
    isLoading,
    error,
    mutate,
    updateOffer,
  };

  return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
}

export function useOffer() {
  const context = useContext(OfferContext);
  if (context === undefined) {
    throw new Error('useOffer must be used within an OfferProvider');
  }
  return context;
}

function OfferContent() {
  const { offer, products, plans, isLoading, error, mutate, updateOffer } = useOffer();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Record<string, [string]>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [originalOffer, setOriginalOffer] = useState<Offer | null>(null);
  const [pendingRemovals, setPendingRemovals] = useState<string[]>([]);

  // Get initial tab from hash or default to "details"
  const initialTab = window.location.hash.replace('#', '') || 'details';
  const [activeTab, setActiveTab] = useState(initialTab);

  // Update hash when tab changes
  const handleTabChange = (value: string) => {
    setActiveTab(value);
    window.location.hash = value;
  };

  // Update active tab when hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        setActiveTab(hash);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  useEffect(() => {
    if (offer && !originalOffer) {
      setOriginalOffer(cloneDeep(offer));
    }
  }, [offer, originalOffer]);

  // Track changes with deep comparison
  useEffect(() => {
    if (!originalOffer || !offer) {
      return;
    }

    // Create clean versions of the offers for comparison
    const cleanOriginal = cloneDeep(originalOffer);
    const cleanCurrent = cloneDeep(offer);

    // Remove timestamps that might cause false positives
    delete cleanOriginal.updated_at;
    delete cleanCurrent.updated_at;

    const hasChanges = !isEqual(cleanOriginal, cleanCurrent);
    setHasUnsavedChanges(hasChanges);
  }, [originalOffer, offer]);

  // Handle navigation/reload warnings - only for page unload
  useBeforeUnload(
    useCallback(
      (event) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
          return (event.returnValue = "You have unsaved changes. Are you sure you want to leave?");
        }
      },
      [hasUnsavedChanges]
    )
  );

  // Handle route changes - only for navigation away from offers section
  useEffect(() => {
    if (!hasUnsavedChanges) return;

    const handleBeforeNavigate = (e: PopStateEvent) => {
      const newPath = window.location.pathname;
      // Only show warning if navigating away from offers section
      if (!newPath.startsWith('/store/offers')) {
        // if (!window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
        //   e.preventDefault();
        //   window.history.pushState(null, "", window.location.pathname);
        // }
        window.history.pushState(null, "", window.location.pathname);
      }
    };

    window.addEventListener("popstate", handleBeforeNavigate);
    return () => window.removeEventListener("popstate", handleBeforeNavigate);
  }, [hasUnsavedChanges]);

  const handleRemoveItem = useCallback((itemId: string) => {
    console.log('Removing item:', itemId);
    // todo: plan_HM73dz11q3PEJx 
    setPendingRemovals(prev => [...prev, itemId]);
    setHasUnsavedChanges(true);
  }, []);

  const handleUndoRemove = useCallback((itemId: string) => {
    setPendingRemovals(prev => prev.filter(id => id !== itemId));
    setHasUnsavedChanges(true);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!offer) return;
    
    console.log('Saving offer:', offer);
    console.log('Offer variant_category_id:', offer.variant_category_id);
    
    setIsSubmitting(true);
    setErrors({});

    try {
      // Apply pending removals before saving
      const updatedItems = offer.items.filter(item => !pendingRemovals.includes(item.id));
      const offerToSave = {
        ...offer,
        items: updatedItems
      };

      console.log('Offer to save:', offerToSave);
      
      const response = await apiClient.put(`/store/offers/${offer.id}`, offerToSave);
      console.log('Save response:', response.data);
      
      setOriginalOffer(response.data);
      setPendingRemovals([]); // Clear pending removals after successful save
      mutate();
      toast.success("Offer saved successfully");
      setHasUnsavedChanges(false);
    } catch (error: any) {
      console.error('Error saving offer:', error);
      if (error.response?.status === 422 && error.response.data.errors) {
        setErrors(error.response.data.errors);
        toast.error("Please correct the validation errors");
      } else {
        toast.error("Failed to save offer");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddItem = (itemId: string, type: 'product' | 'plan', role: 'core' | 'upsell') => {
    if (!offer) return;
    
    // Check if item already exists
    if (offer.items.some(item => item.purchasable_id === itemId && (item.type || item.purchasable_type) === type)) {
      setErrors(prev => ({
        ...prev,
        items: [`Item already exists in this offer`]
      }));
      return;
    }
    
    const itemDetails = type === 'plan' 
      ? plans?.find(p => p.id === itemId)
      : products?.find(p => p.id === itemId);

    if (!itemDetails) return;

    // For plans, create a single item
    if (type === 'plan') {
      const newItem: OfferItem = {
        id: `temp-${Date.now()}`, // Temporary ID, will be replaced by the server
        object: 'offer_item',
        name: itemDetails.name, // Use the plan name as the item name
        type: type, // Set the type directly on the item
        role,
        purchasable_id: itemId,
        purchasable_type: type,
        purchasable_name: itemDetails.name,
        variant_properties: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      
      updateOffer({
        items: [...offer.items, newItem]
      });
    }
    // For products, the PricingTab will handle adding items with charges
  };

  if (isLoading) {
    return (
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <div className="h-8 w-48 bg-gray-200 rounded animate-pulse"></div>
          <div className="flex gap-2">
            <div className="h-9 w-24 bg-gray-200 rounded animate-pulse"></div>
            <div className="h-9 w-32 bg-gray-200 rounded animate-pulse"></div>
          </div>
        </div>

        <div className="h-10 w-full max-w-md bg-gray-200 rounded animate-pulse"></div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2 h-[600px] bg-gray-100 rounded animate-pulse"></div>
          <div className="space-y-6">
            <div className="h-40 bg-gray-100 rounded animate-pulse"></div>
            <div className="h-40 bg-gray-100 rounded animate-pulse"></div>
            <div className="h-40 bg-gray-100 rounded animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error && 'response' in error && error.response?.status === 404) {
    return (
      <div className="p-6">
        <div className="text-center py-12">
          <div className="mx-auto h-12 w-12 text-gray-400 mb-4">
            <MessageCircleWarningIcon className="h-12 w-12" />
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Offer Not Found</h1>
          <p className="text-gray-600 mb-6">The offer you're looking for doesn't exist or has been deleted.</p>
          <Button asChild>
            <Link to="/store/offers">Back to Offers</Link>
          </Button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="text-center py-12">
          <div className="mx-auto h-12 w-12 text-red-500 mb-4">
            <MessageCircleWarningIcon className="h-12 w-12" />
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Something went wrong</h1>
          <p className="text-gray-600 mb-6">We couldn't load the necessary data. Please try again later.</p>
          <div className="flex gap-4 justify-center">
            <Button asChild variant="outline">
              <Link to="/store/offers">Back to Offers</Link>
            </Button>
            <Button onClick={() => window.location.reload()}>
              Retry
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!offer) return null;

  return (
    <div className="p-6 ">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Offer Details</h1>
        <div className="flex gap-2 ">
          <Button 
            disabled={isSubmitting || !hasUnsavedChanges}
            onClick={handleSubmit}
            className={hasUnsavedChanges ? "bg-blue-600 hover:bg-blue-700 " : ""}
          >
            {isSubmitting ? "Saving..." : hasUnsavedChanges ? "Save Changes*" : "Save Changes"}
          </Button>
        </div>
      </div>

      {/* Global Validation Errors */}
      {Object.keys(errors).length > 0 && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
          <h3 className="text-sm font-medium text-red-800 mb-2">Please correct the following errors:</h3>
          <ul className="list-disc list-inside text-sm text-red-700">
            {Object.entries(errors).map(([field, messages]) => (
              <li key={field}>
                <span className="font-medium">{field}:</span> {messages.join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )}

      <Tabs value={activeTab} onValueChange={handleTabChange} className="space-y-6">
        <TabsList>
          <TabsTrigger value="details">Details</TabsTrigger>
          <TabsTrigger value="pricing">Pricing</TabsTrigger>
          <TabsTrigger value="transactions">Transactions</TabsTrigger>
          <TabsTrigger value="upsells">Upsells</TabsTrigger>
          <TabsTrigger value="settings">Settings</TabsTrigger>
        </TabsList>

        <TabsContent value="details">
          <DetailsTab 
            offer={{
              ...offer,
              items: offer.items.filter(item => !pendingRemovals.includes(item.id))
            }}
            products={products || []}
            plans={plans || []}
            updateOffer={updateOffer}
            STATUS_OPTIONS={STATUS_OPTIONS}
            errors={errors}
          />
        </TabsContent>

        <TabsContent value="pricing">
          <PricingTab 
            offer={{
              ...offer,
              items: offer.items.filter(item => !pendingRemovals.includes(item.id))
            }}
            products={products}
            plans={plans}
            handleAddItem={handleAddItem}
            handleRemoveItem={handleRemoveItem}
            errors={errors}
            pendingRemovals={pendingRemovals}
            onUndoRemove={handleUndoRemove}
          />
        </TabsContent>

        <TabsContent value="upsells">
          <UpsellsTab 
            offer={{
              ...offer,
              items: offer.items.filter(item => !pendingRemovals.includes(item.id))
            }}
            products={products || []}
            plans={plans || []}
            updateOffer={updateOffer}
            errors={errors}
            pendingRemovals={pendingRemovals}
            onUndoRemove={handleUndoRemove}
          />
        </TabsContent>

        <TabsContent value="settings">
          <SettingsTab 
            offer={offer}
            updateOffer={updateOffer}
            errors={errors}
            isSubmitting={isSubmitting}
            INTENT_OPTIONS={INTENT_OPTIONS}
            STATUS_OPTIONS={STATUS_OPTIONS}
          />
        </TabsContent>

        <TabsContent value="transactions">
          <TransactionsTab offerId={offer.id} />
        </TabsContent>
      </Tabs>

      {/* Debug Information */}
      <div className="mt-8 border-t border-gray-200 pt-4">
        <details className="text-sm">
          <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
          <div className="mt-2 space-y-4">
            <div>
              <h3 className="font-medium mb-1">Current Offer State:</h3>
              <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96">
                {JSON.stringify(offer, null, 2)}
              </pre>
            </div>
            {Object.keys(errors).length > 0 && (
              <div>
                <h3 className="font-medium mb-1">Validation Errors:</h3>
                <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-red-600">
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </div>
            )}
          </div>
        </details>
      </div>
    </div>
  );
}

export default function OfferDetailPage() {
  const { offerId } = useParams();
  if (!offerId) return null;
  
  return (
    <OfferProvider offerId={offerId}>
      <OfferContent />
    </OfferProvider>
  );
}