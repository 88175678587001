import { Text, Flex } from "@radix-ui/themes";
import { Plus, Package, X, RotateCcw, Check, ChevronsUpDown, Search, DollarSign } from "lucide-react";
import { useState, useMemo, useEffect } from "react";
import { Offer, OfferItem, TabProps } from "../types";
import { Product, Plan, Charge } from "~/types/catalog";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Button } from "~/components/ui/button";
import { useToast } from "~/components/ui/use-toast";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/components/ui/dialog";

function ItemCombobox({ options, onSelect }: { 
  options: { value: string; label: string; description?: string; disabled?: boolean }[];
  onSelect: (value: string) => void;
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Upsell
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>No items found.</CommandEmpty>
            <CommandGroup>
              {options.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                    if (currentValue !== value) {
                      onSelect(currentValue);
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <div>
                    <div>{item.label}</div>
                    {item.description && (
                      <div className="text-sm text-muted-foreground">
                        {item.description}
                      </div>
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

// Format price for display
const formatPrice = (amount: string | number | null): string => {
  if (amount === null) return 'Free';
  
  // Convert to number if it's a string
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
  // Format the number with 2 decimal places
  return `$${numericAmount.toFixed(2)}`;
};

// Component to select charges for a product
function ProductChargeSelector({ 
  product, 
  onConfirm,
  onCancel
}: { 
  product: Product; 
  onConfirm: (product: Product, selectedCharges: (string | number)[]) => void;
  onCancel: () => void;
}) {
  const [selectedCharges, setSelectedCharges] = useState<(string | number)[]>([]);

  const handleToggleCharge = (chargeId: string | number) => {
    if (selectedCharges.includes(chargeId)) {
      setSelectedCharges(selectedCharges.filter(id => id !== chargeId));
    } else {
      setSelectedCharges([...selectedCharges, chargeId]);
    }
  };

  const handleConfirm = () => {
    if (selectedCharges.length > 0) {
      onConfirm(product, selectedCharges);
    }
  };

  return (
    <Dialog open={true} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Select Charges for {product.name}</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="space-y-4 max-h-[400px] overflow-y-auto">
            {product.charges.map(charge => (
              <div 
                key={charge.id} 
                className={`p-3 border rounded flex justify-between items-center cursor-pointer ${
                  selectedCharges.includes(charge.id) ? 'border-blue-500 bg-blue-50' : ''
                }`}
                onClick={() => handleToggleCharge(charge.id)}
              >
                <div>
                  <div className="font-medium">{charge.name}</div>
                  <div className="text-sm text-gray-700">{formatPrice(charge.amount)}</div>
                </div>
                <div>
                  <input 
                    type="checkbox" 
                    checked={selectedCharges.includes(charge.id)} 
                    onChange={() => handleToggleCharge(charge.id)}
                    className="h-5 w-5"
                  />
                </div>
              </div>
            ))}
            {product.charges.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No charges available for this product
              </div>
            )}
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <Button variant="outline" onClick={onCancel}>Cancel</Button>
            <Button 
              onClick={handleConfirm}
              disabled={selectedCharges.length === 0}
            >
              Add Selected Charges
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default function UpsellsTab({ 
  offer, 
  products, 
  plans, 
  updateOffer, 
  errors,
  pendingRemovals = [],
  onUndoRemove
}: TabProps) {
  const [selectedType, setSelectedType] = useState<'product' | 'plan'>('product');
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [isProductChargeSelectorOpen, setIsProductChargeSelectorOpen] = useState(false);
  const { toast } = useToast();

  const { data: plansData, error: plansError, isLoading: isLoadingPlans } = useSWR<{ data: Plan[] }>(
    offer.intent === 'subscription' ? '/pricing/plans' : null,
    getFetcher
  );

  const { data: productsData } = useSWR<{ data: Product[] }>(
    '/catalog/products',
    getFetcher
  );

  // Normalize products to always be an array
  const productsArray = useMemo(() => {
    return Array.isArray(products) ? products : products?.data || [];
  }, [products]);
  
  // Normalize plans to always be an array
  const plansArray = useMemo(() => {
    return Array.isArray(plans) ? plans : plans?.data || [];
  }, [plans]);

  // Find a charge by ID in the products array
  const findChargeById = (chargeId: string | number, productsArray: Product[]): Charge | undefined => {
    for (const product of productsArray) {
      const charge = product.charges.find(c => String(c.id) === String(chargeId));
      if (charge) return charge;
    }
    return undefined;
  };

  const handleAddItem = (selectedItemId: string) => {
    if (!selectedItemId) return;

    // For plans, add directly
    if (selectedType === 'plan') {
      const plan = plansArray.find(p => p.id === selectedItemId);
      if (!plan) return;
      
      // Create a new upsell item for the plan
      const newItem: OfferItem = {
        id: `temp-${Date.now()}`, // Temporary ID, will be replaced by the server
        object: 'offer_item',
        name: plan.display_name,
        type: 'plan',
        role: 'upsell',
        purchasable_id: selectedItemId,
        purchasable_type: 'plan',
        purchasable_name: plan.display_name,
        // Use the first charge if available
        charge_id: plan.charges.length > 0 ? Number(plan.charges[0].id) : undefined,
        variant_properties: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      updateOffer({
        items: [...offer.items, newItem]
      });

      toast({
        title: "Success",
        description: `Added ${plan.display_name} as an upsell`
      });
    } else {
      // For products, open the charge selector
      const product = productsArray.find(p => p.id === selectedItemId);
      if (product) {
        setSelectedProductId(selectedItemId);
        setIsProductChargeSelectorOpen(true);
      }
    }
  };

  const handleProductChargesSelected = (product: Product, selectedCharges: (string | number)[]) => {
    // Create offer items for each selected charge
    const newItems = selectedCharges.map(chargeId => {
      // Find the charge by ID
      const charge = findChargeById(chargeId, [product]);
      
      if (!charge) return null;

      // Create a new offer item for this charge
      return {
        id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
        name: `${product.name} - ${charge.name}`, // Include charge name in the item name
        object: 'offer_item',
        type: 'product' as const,
        role: 'upsell' as const,
        purchasable_id: product.id,
        purchasable_type: 'product' as const,
        purchasable_name: product.name,
        charge_id: Number(charge.id),
        variant_properties: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
    }).filter(Boolean) as OfferItem[];

    // Add the new items to the offer
    updateOffer({
      items: [...offer.items, ...newItems]
    });

    // Close the selector
    setIsProductChargeSelectorOpen(false);
    setSelectedProductId(null);

    toast({
      title: "Success",
      description: `Added ${newItems.length} charges from ${product.name} as upsells`
    });
  };

  const handleRemoveItem = (itemId: string) => {
    const updatedItems = offer.items.filter(item => item.id !== itemId);
    updateOffer({ items: updatedItems });
  };

  // Get upsell items with their details
  const upsellItems = useMemo(() => {
    return offer.items
      .filter(item => item.role === 'upsell')
      .map(item => {
        // Use the item's type directly if available, otherwise use purchasable_type
        const itemType = item.type || item.purchasable_type;
        const details = itemType === 'plan' 
          ? plansArray.find(p => p.id === item.purchasable_id)
          : productsArray.find(p => p.id === item.purchasable_id);
        
        // Find the charge if charge_id is available
        let charge;
        if (item.charge_id) {
          if (itemType === 'plan' && details) {
            charge = (details as Plan).charges.find(c => String(c.id) === String(item.charge_id));
          } else if (details) {
            charge = (details as Product).charges.find(c => String(c.id) === String(item.charge_id));
          }
        }
        
        return {
          ...item,
          details,
          charge
        };
      });
  }, [offer.items, plansArray, productsArray]);

  const removedItems = useMemo(() => {
    return offer.items
      .filter(item => pendingRemovals.includes(item.id))
      .map(item => {
        // Use the item's type directly if available, otherwise use purchasable_type
        const itemType = item.type || item.purchasable_type;
        const details = itemType === 'plan' 
          ? plansArray.find(p => p.id === item.purchasable_id)
          : productsArray.find(p => p.id === item.purchasable_id);
        
        return {
          ...item,
          details
        };
      });
  }, [offer.items, pendingRemovals, plansArray, productsArray]);
  
  const getAvailableOptions = () => {
    if (selectedType === 'plan') {
      if (isLoadingPlans) return [{ value: '', label: 'Loading plans...', disabled: true }];
      if (plansError) return [{ value: '', label: 'Failed to load plans', disabled: true }];

      // Filter out plans that are already added as upsells
      const existingPlanIds = new Set(
        upsellItems
          .filter(item => (item.type || item.purchasable_type) === 'plan')
          .map(item => item.purchasable_id)
      );
      
      return plansArray
        .filter(plan => !existingPlanIds.has(plan.id))
        .map(plan => ({
          value: plan.id,
          label: plan.display_name,
          description: plan.description
        }));
    }

    // Filter out products that are already fully added (all charges used)
    const existingProductCharges = upsellItems
      .filter(item => (item.type || item.purchasable_type) === 'product')
      .reduce((acc, item) => {
        const productId = item.purchasable_id;
        if (!acc[productId]) acc[productId] = new Set();
        if (item.charge_id) acc[productId].add(item.charge_id);
        return acc;
      }, {} as Record<string, Set<number | string | undefined>>);

    return productsArray
      .filter(product => {
        // Include product if not all charges are used
        const usedCharges = existingProductCharges[product.id] || new Set();
        return usedCharges.size < product.charges.length;
      })
      .map(product => ({
        value: product.id,
        label: product.name,
        description: product.description
      }));
  };

  const handleProductSelect = (productId: string) => {
    // For plans, add directly
    if (selectedType === 'plan') {
      handleAddItem(productId);
      return;
    }

    // For products, open the charge selector
    const product = productsArray.find(p => p.id === productId);
    if (product) {
      setSelectedProductId(productId);
      setIsProductChargeSelectorOpen(true);
    }
  };

  return (
    <div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Upsells</h2>
          <div className="flex items-center gap-4">
            <div className="flex gap-2">
              <Button 
                variant={selectedType === 'product' ? 'default' : 'outline'} 
                onClick={() => setSelectedType('product')}
              >
                Product
              </Button>
              <Button 
                variant={selectedType === 'plan' ? 'default' : 'outline'} 
                onClick={() => setSelectedType('plan')}
              >
                Plan
              </Button>
            </div>
            <ItemCombobox 
              options={getAvailableOptions()}
              onSelect={handleProductSelect}
            />
          </div>
        </div>

        <div className="space-y-4">
          {/* Active Items */}
          {upsellItems.map((item) => {
            return (
              <div key={item.id} className="flex justify-between items-start p-4 bg-gray-50 rounded border-l-4 border-blue-500">
                <div>
                  <h3 className="font-medium">{item.name || item.purchasable_name}</h3>
                  {item.details?.description && (
                    <p className="text-sm text-gray-600">{item.details.description}</p>
                  )}
                  <div className="flex items-center gap-4 mt-2">
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <Package className="h-4 w-4" />
                      {(item.type || item.purchasable_type) === 'plan' ? 'Plan' : 'Product'}
                    </div>
                    {item.charge && (
                      <div className="flex items-center gap-2 text-sm text-gray-700">
                        <DollarSign className="h-4 w-4" />
                        {item.charge.name}: {formatPrice(item.charge.amount)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <Button variant="outline" asChild>
                    <Link to={`/store/${item.type || item.purchasable_type}s/${item.purchasable_id}`}>
                      View Details
                    </Link>
                  </Button>
                  <Button 
                    variant="destructive" 
                    onClick={() => handleRemoveItem(item.id)}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            );
          })}

          {/* Pending Removals */}
          {removedItems.map((item) => {
            return (
              <div key={item.id} className="flex justify-between items-start p-4 bg-gray-50 rounded border-l-4 border-red-500 opacity-60">
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="font-medium">{item.name || item.purchasable_name}</h3>
                    <span className="text-xs text-red-600 font-medium">Pending Removal</span>
                  </div>
                  {item.details?.description && (
                    <p className="text-sm text-gray-600">{item.details.description}</p>
                  )}
                  <div className="flex items-center gap-2 mt-1 text-sm text-gray-600">
                    <Package className="h-4 w-4" />
                    {(item.type || item.purchasable_type) === 'plan' ? 'Plan' : 'Product'}
                  </div>
                </div>
                <div>
                  <Button 
                    variant="outline" 
                    onClick={() => onUndoRemove?.(item.id)}
                  >
                    <RotateCcw className="w-4 h-4 mr-2" />
                    Undo
                  </Button>
                </div>
              </div>
            );
          })}

          {upsellItems.length === 0 && removedItems.length === 0 && (
            <div className="text-center py-12 border-2 border-dashed rounded">
              <Package className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">No upsells added</h3>
              <p className="text-sm text-gray-600 mt-1">
                Add products or plans as upsells to this offer
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Product Charge Selector Dialog */}
      {isProductChargeSelectorOpen && selectedProductId && (
        <ProductChargeSelector
          product={productsArray.find(p => p.id === selectedProductId)!}
          onConfirm={handleProductChargesSelected}
          onCancel={() => {
            setIsProductChargeSelectorOpen(false);
            setSelectedProductId(null);
          }}
        />
      )}
    </div>
  );
} 