import { Card, Button, Text } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { 
  Package, 
  CalendarDays, 
  ImageIcon, 
  ArrowUpRight, 
  Mail, 
  ArrowRight, 
  Webhook, 
  Copy, 
  Check, 
  Tag, 
  Globe, 
  Settings, 
  DollarSign,
  CloudLightning
} from "lucide-react";
import { Offer, OfferItem, VariantCategory } from "../types";
import { Product, Plan, Charge } from "~/types/catalog";
import { RadioGroup } from "@radix-ui/themes";
import { useState, useMemo, useEffect } from "react";
import { useToast } from "~/components/ui/use-toast";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Label } from "~/components/ui/label";
import { Badge } from "~/components/ui/badge";
import { Separator } from "~/components/ui/separator";
import { cn } from "~/lib/utils";

type Props = {
  offer: Offer;
  products: Product[] | { data: Product[] };
  plans: Plan[] | { data: Plan[] };
  updateOffer: (updates: Partial<Offer>) => void;
  STATUS_OPTIONS: { id: string; name: string; }[];
  errors: Record<string, [string]>;
};

// Format price for display (amounts are in cents)
const formatPrice = (amount: string | number | null): string => {
  if (amount === null) return 'Free';
  
  // Convert to number if it's a string
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
  // Format the number as cents
  return `${(numericAmount / 100).toFixed(2)}`;
};

export default function DetailsTab({ offer, products, plans, updateOffer, STATUS_OPTIONS, errors }: Props) {
  const [isCopied, setIsCopied] = useState<string | false>(false);
  const { toast } = useToast();
  
  // Fetch variant categories
  const { data: categoriesResponse } = useSWR<{data: VariantCategory[] }>(
    '/store/variant_categories',
    getFetcher
  );
  
  const variantCategories = categoriesResponse?.data || [];
  
  // Normalize products to always be an array
  const productsArray = useMemo(() => {
    return Array.isArray(products) ? products : products?.data || [];
  }, [products]);
  
  // Normalize plans to always be an array
  const plansArray = useMemo(() => {
    return Array.isArray(plans) ? plans : plans?.data || [];
  }, [plans]);

  // Function to get the test checkout URL
  const getTestCheckoutUrl = (liveUrl: string): string => {
    const url = new URL(liveUrl);
    const pathParts = url.pathname.split('/');
    
    // Find the offer ID in the path (usually the last segment)
    const offerIdIndex = pathParts.length - 1;
    
    // Insert 'test' after the offer ID
    if (offerIdIndex > 0) {
      pathParts.splice(offerIdIndex + 1, 0, 'test');
      url.pathname = pathParts.join('/');
    }
    
    return url.toString();
  };

  const handleCopyLink = async (url: string, type: string = 'live') => {
    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(type);
      setTimeout(() => setIsCopied(false), 2000);
      toast({
        title: "Success",
        description: "Link copied to clipboard"
      });
    } catch (err) {
      toast({
        title: "Error",
        description: "Failed to copy link",
        variant: "destructive"
      });
    }
  };

  // Get core items
  const coreItems = offer.items
    .filter(item => item.role === 'core')
    .map(item => {
      // Use the item's type directly if available, otherwise use purchasable_type
      const itemType = item.type || item.purchasable_type;
      const details = itemType === 'plan' 
        ? plansArray.find(p => p.id === item.purchasable_id)
        : productsArray.find(p => p.id === item.purchasable_id);
      
      // Find the charge if charge_id is available
      let charge;
      if (item.charge_id) {
        if (itemType === 'plan' && details) {
          charge = (details as Plan).charges.find(c => String(c.id) === String(item.charge_id));
        } else if (details) {
          charge = (details as Product).charges.find(c => String(c.id) === String(item.charge_id));
        }
      }
      
      return {
        ...item,
        details,
        charge
      };
    });

  // Get upsell items
  const upsellItems = offer.items
    .filter(item => item.role === 'upsell')
    .map(item => {
      // Use the item's type directly if available, otherwise use purchasable_type
      const itemType = item.type || item.purchasable_type;
      const details = itemType === 'plan' 
        ? plansArray.find(p => p.id === item.purchasable_id)
        : productsArray.find(p => p.id === item.purchasable_id);
      
      // Find the charge if charge_id is available
      let charge;
      if (item.charge_id) {
        if (itemType === 'plan' && details) {
          charge = (details as Plan).charges.find(c => String(c.id) === String(item.charge_id));
        } else if (details) {
          charge = (details as Product).charges.find(c => String(c.id) === String(item.charge_id));
        }
      }
      
      return {
        ...item,
        details,
        charge
      };
    });

  // Render an item card
  const renderItemCard = (item: OfferItem & { details?: any, charge?: Charge }) => {
    // Use the item's type directly if available, otherwise use purchasable_type
    const itemType = item.type || item.purchasable_type;
    
    return (
      <div key={item.id} className="group py-3 border-b last:border-b-0 hover:bg-gray-50 transition-colors">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="font-medium text-gray-900">{item.name || item.purchasable_name}</h4>
            {(item.details?.description) && (
              <p className="text-sm text-gray-600 mt-1 line-clamp-2">
                {item.details.description}
              </p>
            )}
            <div className="flex items-center gap-3 mt-2">
              <Badge variant="outline" className="flex items-center gap-1">
                {itemType === 'plan' ? <CalendarDays className="h-3 w-3" /> : <Package className="h-3 w-3" />}
                <span>{itemType === 'plan' ? 'Plan' : 'Product'}</span>
              </Badge>
              {item.charge && (
                <Badge variant="secondary" className="flex items-center gap-1">
                  <DollarSign className="h-3 w-3" />
                  <span>${formatPrice(item.charge.amount)}</span>
                </Badge>
              )}
              {item.is_default && (
                <Badge className="bg-blue-100 text-blue-800 hover:bg-blue-100 border-blue-200">
                  Default
                </Badge>
              )}
            </div>
          </div>
          <Link 
            to={`/catalog/${itemType}s/${item.purchasable_id}`}
            className="opacity-0 group-hover:opacity-100 inline-flex items-center justify-center px-3 py-1.5 text-sm font-medium rounded-md border border-gray-300 bg-white hover:bg-gray-50 transition-all"
          >
            View
          </Link>
        </div>
      </div>
    );
  };

  // Get the selected variant category
  const selectedCategory = variantCategories.find(cat => cat.id === offer.variant_category_id);

  // Get the current status option
  const currentStatus = STATUS_OPTIONS.find(option => option.id === offer.status);

  // Check if the offer has scenarios
  const hasScenarios = offer.scenarios && offer.scenarios.object === 'list' && 
    offer.scenarios.data && offer.scenarios.data.length > 0;

  return (
    <div className="grid grid-cols-3 gap-5">
      {/* Left Column (2/3) - Core Items and Upsell Items */}
      <div className="col-span-2 space-y-5">
        <div className="bg-white rounded-lg border">
          <div className="px-4 py-3 border-b">
            <h2 className="font-medium text-gray-900">Core Items</h2>
          </div>
          
          <div className="px-4">
            {coreItems.length > 0 ? (
              <div className="divide-y">
                {coreItems.map((item) => renderItemCard(item))}
              </div>
            ) : (
              <div className="text-center py-8 border-2 border-dashed rounded-lg my-4">
                <Package className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                <h3 className="text-sm font-medium text-gray-900">No core items added</h3>
                <p className="text-xs text-gray-600 mt-1">
                  Add {offer.intent === 'subscription' ? 'plans' : 'products'} in the Pricing tab
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg border">
          <div className="px-4 py-3 border-b">
            <h2 className="font-medium text-gray-900">Upsell Items</h2>
          </div>
          
          <div className="px-4">
            {upsellItems.length > 0 ? (
              <div className="divide-y">
                {upsellItems.map((item) => renderItemCard(item))}
              </div>
            ) : (
              <div className="text-center py-8 border-2 border-dashed rounded-lg my-4">
                <ArrowRight className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                <h3 className="text-sm font-medium text-gray-900">No upsells added</h3>
                <p className="text-xs text-gray-600 mt-1">
                  Add upsell items in the Upsells tab
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Scenarios Section */}
        {hasScenarios && (
          <div className="bg-white rounded-lg border">
            <div className="px-4 py-3 border-b flex items-center gap-2">
              <CloudLightning className="h-4 w-4 text-gray-700" />
              <h2 className="font-medium text-gray-900">Used in Scenarios</h2>
            </div>
            
            <div className="px-4">
              <div className="divide-y">
                {offer.scenarios.data.map((scenario) => (
                  <div key={scenario.id} className="py-3">
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="font-medium text-gray-900">
                          {scenario.display_name || `Scenario ${scenario.id.substring(0, 8)}`}
                        </h4>
                        <p className="text-sm text-gray-600 mt-1">
                          Flow: {scenario.flow}
                        </p>
                        <div className="mt-2">
                          <Link 
                            to={`/convert/workflows/${scenario.flow}/paywalls`}
                            className="text-xs text-primary hover:underline flex items-center gap-1"
                          >
                            <span>View in workflow</span>
                            <ArrowUpRight className="h-3 w-3" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Right Column (1/3) - Status, Provisioning, URLs, Variant Category */}
      <div className="space-y-5">
        {/* Offer Status */}
        <div className="bg-white rounded-lg border">
          <div className="px-4 py-3 border-b flex items-center gap-2">
            <Tag className="h-4 w-4 text-gray-700" />
            <h2 className="font-medium text-gray-900">Offer Status</h2>
          </div>
          
          <div className="p-4">
            <div className="flex items-center gap-3">
              <div className={cn(
                "w-3 h-3 rounded-full",
                offer.status === 'published' ? "bg-green-500" : "bg-amber-500"
              )}></div>
              <span className="font-medium capitalize">{offer.status}</span>
            </div>
            
            {errors.status && (
              <div className="text-red-500 text-sm mt-2">{errors.status}</div>
            )}
          </div>
        </div>

        {/* Variant Category */}
        <div className="bg-white rounded-lg border overflow-hidden">
          <div className="px-4 py-3 border-b flex items-center gap-2">
            <Settings className="h-4 w-4 text-gray-700" />
            <h2 className="font-medium text-gray-900">Variant Category</h2>
          </div>
          
          <div className="">
            {selectedCategory ? (
              <div className="bg-gray-50 p-4">
                <h3 className="text-sm font-medium text-gray-900">{selectedCategory.name}</h3>
                <p className="text-xs text-gray-600 mt-1">
                  {selectedCategory.fields?.length || 0} fields defined
                </p>
                <p className="text-xs text-gray-500 mt-2">
                  All variants in this offer use this category's fields
                </p>
              </div>
            ) : (
              <p className="text-sm text-gray-600">No variant category selected</p>
            )}
            
            {errors.variant_category_id && (
              <div className="text-red-500 text-sm mt-2">{errors.variant_category_id}</div>
            )}
          </div>
        </div>

        {/* Provisioning */}
        <div className="bg-white rounded-lg border">
          <div className="px-4 py-3 border-b flex items-center gap-2">
            <Webhook className="h-4 w-4 text-gray-700" />
            <h2 className="font-medium text-gray-900">Provisioning</h2>
          </div>
          
          <div className="bg-gray-50 p-4">
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-gray-900">Transaction Webhook</h3>
                {offer.transaction_webhook_url ? (
                  <div className="mt-1 text-sm text-gray-600 break-all">
                    <code className="bg-gray-100 px-1 py-0.5 rounded text-xs">
                      {offer.transaction_webhook_url}
                    </code>
                  </div>
                ) : (
                  <p className="text-sm text-gray-500 mt-1">No webhook configured</p>
                )}
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-gray-900">Provisioning Mode</h3>
                <p className="text-sm text-gray-600 mt-1 capitalize">
                  {offer.provisioning_mode || "Not set"}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Checkout URLs */}
        <div className="bg-white rounded-lg border">
          <div className="px-4 py-3 border-b flex items-center gap-2">
            <Globe className="h-4 w-4 text-gray-700" />
            <h2 className="font-medium text-gray-900">Checkout URLs</h2>
          </div>
          
          <div className="bg-gray-50 p-4">
            <div className="space-y-4">
              {/* Live Checkout URL */}
              <div>
                <div className="flex justify-between items-start">
                  <h3 className="text-sm font-medium text-gray-900">Live Checkout URL</h3>
                  <div className="flex gap-2">
                    <a 
                      href={offer.urls.checkout}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center justify-center p-1 rounded-md border border-gray-300 bg-white hover:bg-gray-50 transition-colors"
                    >
                      <ArrowUpRight className="h-3.5 w-3.5" />
                    </a>
                    <button
                      onClick={() => handleCopyLink(offer.urls.checkout)}
                      className={cn(
                        "inline-flex items-center justify-center p-1 rounded-md border bg-white transition-colors",
                        isCopied === 'live' 
                          ? "border-green-300 text-green-600 hover:bg-green-50" 
                          : "border-gray-300 hover:bg-gray-50"
                      )}
                    >
                      {isCopied === 'live' ? <Check className="h-3.5 w-3.5" /> : <Copy className="h-3.5 w-3.5" />}
                    </button>
                  </div>
                </div>
                <a 
                  href={offer.urls.checkout} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:underline mt-1 inline-block break-all"
                >
                  {new URL(offer.urls.checkout).host + new URL(offer.urls.checkout).pathname}
                </a>
              </div>
              
              {/* Test Checkout URL */}
              <div>
                <div className="flex justify-between items-start">
                  <h3 className="text-sm font-medium text-gray-900">Test Checkout URL</h3>
                  <div className="flex gap-2">
                    <a 
                      href={getTestCheckoutUrl(offer.urls.checkout)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center justify-center p-1 rounded-md border border-gray-300 bg-white hover:bg-gray-50 transition-colors"
                    >
                      <ArrowUpRight className="h-3.5 w-3.5" />
                    </a>
                    <button
                      onClick={() => handleCopyLink(getTestCheckoutUrl(offer.urls.checkout), 'test')}
                      className={cn(
                        "inline-flex items-center justify-center p-1 rounded-md border bg-white transition-colors",
                        isCopied === 'test' 
                          ? "border-green-300 text-green-600 hover:bg-green-50" 
                          : "border-gray-300 hover:bg-gray-50"
                      )}
                    >
                      {isCopied === 'test' ? <Check className="h-3.5 w-3.5" /> : <Copy className="h-3.5 w-3.5" />}
                    </button>
                  </div>
                </div>
                <a 
                  href={getTestCheckoutUrl(offer.urls.checkout)} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm text-blue-600 hover:underline mt-1 inline-block break-all"
                >
                  {new URL(getTestCheckoutUrl(offer.urls.checkout)).host + new URL(getTestCheckoutUrl(offer.urls.checkout)).pathname}
                </a>
                <Badge variant="outline" className="mt-2 bg-yellow-50 text-yellow-800 border-yellow-200">
                  Test Mode
                </Badge>
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-gray-900">Checkout Mode</h3>
                <p className="text-sm text-gray-600 mt-1 capitalize">
                  {offer.checkout_mode || "Not set"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 