import { useParams, Link } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { AlertCircle, ExternalLink, Webhook, RefreshCcw } from "lucide-react";
import { format } from "date-fns";
import * as React from "react";
import apiClient from "~/lib/apiClient";
import { toast } from "react-toastify";
import { Button } from "~/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { Badge } from "~/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { Separator } from "~/components/ui/separator";
import { cn } from "~/lib/utils";

type TransactionItem = {
  id: string | number;
  purchasable_id: string | number;
  purchasable_type: 'product' | 'plan' | 'package';
  name?: string;
  quantity?: number;
  amount_total?: {
    amount: string | number;
    currency: string;
  };
  amount_subtotal?: {
    amount: string | number;
    currency: string;
  };
  amount_discount?: {
    amount: string | number;
    currency: string;
  };
  amount_tax?: {
    amount: string | number;
    currency: string;
  };
  transaction?: any; // Nested transaction data
  created_at?: string;
  updated_at?: string;
};

type Customer = {
  id: string | number;
  name: string | null;
  email: string | null;
  address?: string;
  organization_id?: number;
  reference_id?: string;
  payment_methods?: (string | number)[];
  created_at?: string;
  updated_at?: string;
};

type Transaction = {
  id: string;
  intent: 'order' | 'subscription';
  current_state: 'created' | 'paid' | 'failed' | 'refunded';
  amount_total: string | number;
  amount_subtotal?: string | number;
  amount_fees?: string | number;
  amount_net?: string | number;
  currency: string;
  customer: Customer | null;
  items: TransactionItem[];
  completed_at: string | null;
  created_at: string;
  payment_method?: {
    type: string;
    last4?: string;
  };
  provider_id?: string;
  provider_name?: string;
  offer_id?: number;
};

type WebhookTestResponse = {
  success: boolean;
  message: string;
  request?: {
    url: string;
    method: string;
    headers: Record<string, string>;
    body: any;
  };
  response?: {
    status: number;
    headers: Record<string, string>;
    body: any;
  };
};

function formatCurrency(amount: string | number | undefined | {amount: string | number, currency?: string}, currency?: string) {
  if (!amount) return '-';
  
  // Handle amount objects with nested amount property
  if (typeof amount === 'object' && amount !== null && 'amount' in amount) {
    const amountValue = Number(amount.amount) / 100; // Convert cents to dollars
    const amountCurrency = amount.currency || currency;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: amountCurrency || 'USD'
    }).format(amountValue);
  }
  
  // Handle direct amount values
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD'
  }).format(Number(amount) / 100); // Convert cents to dollars
}

function TransactionStatusBadge({ status }: { status: Transaction['current_state'] }) {
  const variants: Record<Transaction['current_state'], string> = {
    paid: 'bg-green-100 text-green-700',
    failed: 'bg-red-100 text-red-700',
    refunded: 'bg-yellow-100 text-yellow-700',
    created: 'bg-gray-100 text-gray-700'
  };

  const labels: Record<Transaction['current_state'], string> = {
    paid: 'Paid',
    failed: 'Failed',
    refunded: 'Refunded',
    created: 'Pending'
  };

  return (
    <Badge className={cn("font-medium", variants[status])}>
      {labels[status]}
    </Badge>
  );
}

function WebhookTestDialog({ 
  transactionId, 
  isOpen, 
  onClose 
}: { 
  transactionId: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [result, setResult] = React.useState<WebhookTestResponse | null>(null);

  const handleTest = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.post<WebhookTestResponse>(
        `/store/transactions/${transactionId}/test-webhook`
      );
      setResult(response.data);
      if (response.data.success) {
        toast.success("Webhook test completed successfully");
      } else {
        toast.error("Webhook test failed");
      }
    } catch (error) {
      toast.error("Failed to test webhook");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Test Transaction Webhook</DialogTitle>
        </DialogHeader>
        <div className="mt-4">
          {!result ? (
            <div className="text-center py-6">
              <Webhook className="w-12 h-12 mx-auto text-muted-foreground mb-4" />
              <p className="text-sm text-muted-foreground mb-6">
                This will send a test webhook to your configured endpoint. The response will be displayed here.
              </p>
              <Button 
                onClick={handleTest} 
                disabled={isLoading}
              >
                {isLoading ? "Testing..." : "Send Test Webhook"}
              </Button>
            </div>
          ) : (
            <div className="space-y-6">
              <div>
                <div className="font-medium text-sm mb-2">Request</div>
                <pre className="bg-muted p-3 rounded-md text-sm overflow-auto max-h-40">
                  {JSON.stringify(result.request, null, 2)}
                </pre>
              </div>
              
              {result.response && (
                <div>
                  <div className="font-medium text-sm mb-2">Response</div>
                  <div className="flex items-center gap-2 mb-2">
                    <Badge variant={result.response.status < 300 ? "default" : "destructive"}>
                      {result.response.status}
                    </Badge>
                  </div>
                  <pre className="bg-muted p-3 rounded-md text-sm overflow-auto max-h-40">
                    {JSON.stringify(result.response, null, 2)}
                  </pre>
                </div>
              )}

              <div className="flex justify-end gap-3">
                <Button onClick={handleTest} disabled={isLoading}>
                  {isLoading ? "Testing..." : "Test Again"}
                </Button>
                <Button variant="outline" onClick={onClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default function TransactionDetailPage() {
  const { transactionId } = useParams();
  const [isWebhookDialogOpen, setIsWebhookDialogOpen] = React.useState(false);
  const { data: transaction, error, isLoading, mutate } = useSWR<Transaction>(
    transactionId ? `/store/transactions/${transactionId}` : null,
    getFetcher
  );

  if (isLoading) {
    return (
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <div className="h-8 w-48 bg-muted rounded animate-pulse"></div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="h-[400px] bg-muted rounded animate-pulse"></div>
          <div className="h-[400px] bg-muted rounded animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (error && 'response' in error && error.response?.status === 404) {
    return (
      <div className="p-6">
        <div className="text-center py-12">
          <AlertCircle className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
          <h1 className="text-2xl font-bold mb-2">Transaction Not Found</h1>
          <p className="text-muted-foreground mb-6">
            The transaction you're looking for doesn't exist or has been deleted.
          </p>
          <Button asChild>
            <Link to="/store/transactions">Back to Transactions</Link>
          </Button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="text-center py-12">
          <AlertCircle className="h-12 w-12 text-destructive mx-auto mb-4" />
          <h1 className="text-2xl font-bold mb-2">Something went wrong</h1>
          <p className="text-muted-foreground mb-6">
            We couldn't load the transaction data. Please try again later.
          </p>
          <div className="flex gap-4 justify-center">
            <Button variant="outline" asChild>
              <Link to="/store/transactions">Back to Transactions</Link>
            </Button>
            <Button onClick={() => window.location.reload()}>
              <RefreshCcw className="w-4 h-4 mr-2" />
              Try Again
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!transaction) return null;

  // Helper function to get payment method display
  const getPaymentMethodDisplay = () => {
    if (!transaction) return '-';
    
    // Check for payment_method property first
    if (transaction.payment_method) {
      return `${transaction.payment_method.type}${transaction.payment_method.last4 ? ` •••• ${transaction.payment_method.last4}` : ''}`;
    }
    
    // If customer has payment_methods array, show count
    if (transaction.customer?.payment_methods && transaction.customer.payment_methods.length > 0) {
      return `Payment method ID: ${transaction.customer.payment_methods[0]}`;
    }
    
    return '-';
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">Transaction Details</h1>
          <TransactionStatusBadge status={transaction.current_state} />
        </div>
        <div className="flex gap-2">
          <Button 
            variant="outline"
            onClick={() => setIsWebhookDialogOpen(true)}
          >
            <Webhook className="w-4 h-4 mr-2" />
            Test Webhook
          </Button>
          {transaction.current_state === 'paid' && (
            <Button variant="outline">
              Refund
            </Button>
          )}
          <Button 
            variant="outline"
            onClick={() => mutate()}
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Refresh
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {/* Transaction Details */}
        <Card className="col-span-2">
          <CardHeader>
            <CardTitle>Transaction Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Transaction ID</div>
                  <div className="text-sm">{transaction.id}</div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Provider</div>
                  <div className="text-sm">{transaction.provider_name || '-'}</div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Payment type</div>
                  <div className="text-sm">{transaction.intent === 'subscription' ? 'Subscription' : 'One-time'}</div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Paid on</div>
                  <div className="text-sm">
                    {transaction.completed_at 
                      ? format(new Date(transaction.completed_at), 'MMM dd, yyyy')
                      : '-'
                    }
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Customer</div>
                  <div className="text-sm">
                    {transaction.customer?.name || transaction.customer?.reference_id || '-'}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Email</div>
                  <div className="text-sm">
                    {transaction.customer?.email || '-'}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="text-sm text-muted-foreground mb-1">Address</div>
                  <div className="text-sm">
                    {transaction.customer?.address || '-'}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Payment method</div>
                  <div className="text-sm">
                    {getPaymentMethodDisplay()}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Created at</div>
                  <div className="text-sm">
                    {format(new Date(transaction.created_at), 'MMM dd, yyyy HH:mm')}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>

          <Separator />

          <CardContent className="mt-6">
            <h3 className="text-base font-medium mb-4">Items</h3>
            <div className="space-y-4">
              {transaction.items.map((item, index) => {
                // Try to get a display name for the item
                const itemName = item.name || `${item.purchasable_type.charAt(0).toUpperCase() + item.purchasable_type.slice(1)} Item`;
                
                return (
                  <div key={index} className="flex items-start gap-4 p-4 bg-muted rounded-lg">
                    <div className="w-16 h-16 bg-muted-foreground/10 rounded flex-shrink-0"></div>
                    <div className="flex-grow">
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="font-medium">
                            {itemName}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            Price: {formatCurrency(item.amount_total, transaction.currency)}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            Quantity: {item.quantity || 1}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            Type: {item.purchasable_type}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            ID: {item.purchasable_id}
                          </div>
                        </div>
                        {item.purchasable_type === 'plan' && (
                          <Button size="sm" variant="outline">
                            Manage subscription
                          </Button>
                        )}
                      </div>
                    
                    </div>
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>

        {/* Payment Summary */}
        <Card>
          <CardHeader>
            <CardTitle>Payment Summary</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              {transaction.amount_subtotal && (
                <div className="flex justify-between">
                  <span className="text-sm text-muted-foreground">Subtotal</span>
                  <span className="text-sm">{formatCurrency(transaction.amount_subtotal, transaction.currency)}</span>
                </div>
              )}
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Total</span>
                <span className="text-sm font-medium">{formatCurrency(transaction.amount_total, transaction.currency)}</span>
              </div>
              {transaction.amount_fees && (
                <div className="flex justify-between">
                  <span className="text-sm text-muted-foreground">Fees</span>
                  <span className="text-sm">{formatCurrency(transaction.amount_fees, transaction.currency)}</span>
                </div>
              )}
              {transaction.amount_net && (
                <>
                  <Separator className="my-3" />
                  <div className="flex justify-between">
                    <span className="text-sm text-muted-foreground">Net</span>
                    <span className="text-sm">{formatCurrency(transaction.amount_net, transaction.currency)}</span>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Debug Information */}
      <div className="mt-8 border-t pt-4">
        <details className="text-sm">
          <summary className="font-medium text-muted-foreground cursor-pointer">Debug Information</summary>
          <div className="mt-2">
            <pre className="bg-muted p-4 rounded-md overflow-auto">
              {JSON.stringify(transaction, null, 2)}
            </pre>
          </div>
        </details>
      </div>

      <WebhookTestDialog 
        transactionId={transaction.id}
        isOpen={isWebhookDialogOpen}
        onClose={() => setIsWebhookDialogOpen(false)}
      />
    </div>
  );
}