import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { useToast } from "~/components/ui/use-toast";
import { VariantCategory, VariantCategoryField } from "../types";
import { Checkbox } from "~/components/ui/checkbox";
import useSWR from 'swr';
import { getFetcher } from '~/lib/apiClient';

// Define the ItemVariant interface since it's not exported from types.ts
interface ItemVariant {
  id?: string;
  name: string;
  currency: string;
  charge_id: string | number;
  amount: number;
  variant_properties: Record<string, string | number | boolean>;
  is_default?: boolean;
  type?: 'product' | 'plan' | 'package'; // Add package type
}

const SUPPORTED_CURRENCIES = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
  { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
];

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSave: (variant: ItemVariant) => void;
  variant: ItemVariant;
  chargeName?: string;
  variantCategory?: VariantCategory | null; // The single variant category for the offer
};

export default function VariantDialog({ 
  open, 
  onOpenChange, 
  onSave, 
  variant,
  chargeName = '',
  variantCategory = null
}: Props) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editedVariant, setEditedVariant] = useState<ItemVariant>({
    ...variant,
    name: variant.name || `${chargeName} - ${variant.currency}`,
    variant_properties: variant.variant_properties || {}
  });

  // Determine if this is a plan or package variant
  const isPlanVariant = variant.type === 'plan';
  const isPackageVariant = variant.type === 'package';
  const isSubscriptionVariant = isPlanVariant || isPackageVariant;

  // Update the edited variant when the input variant changes
  useEffect(() => {
    if (!variant) return;
    
    setEditedVariant({
      ...variant,
      name: variant.name || `${chargeName} - ${variant.currency}`,
      variant_properties: variant.variant_properties || {}
    });
  }, [variant, chargeName]);

  const handleChange = (field: keyof ItemVariant, value: any) => {
    setEditedVariant(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePropertyChange = (fieldId: string, value: string | number | boolean) => {
    setEditedVariant(prev => ({
      ...prev,
      variant_properties: {
        ...prev.variant_properties,
        [fieldId]: value
      }
    }));
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!editedVariant.name.trim()) {
      toast({
        title: "Validation Error",
        description: "Variant name is required",
        variant: "destructive"
      });
      return;
    }

    // Only validate currency and amount for product variants
    if (!isSubscriptionVariant) {
      if (!editedVariant.currency) {
        toast({
          title: "Validation Error",
          description: "Currency is required",
          variant: "destructive"
        });
        return;
      }

      if (isNaN(editedVariant.amount) || editedVariant.amount <= 0) {
        toast({
          title: "Validation Error",
          description: "Amount must be a positive number",
          variant: "destructive"
        });
        return;
      }
    }

    // Validate required category fields if there's a variant category
    let hasValidationError = false;
    if (variantCategory) {
      variantCategory.fields?.forEach?.(field => {
        if (!field) return; // Skip if field is null or undefined
        if (field.required && 
            (!editedVariant.variant_properties || 
             editedVariant.variant_properties[field.id] === undefined || 
             editedVariant.variant_properties[field.id] === '')) {
          toast({
            title: "Validation Error",
            description: `${field.name} is required for category ${variantCategory.name}`,
            variant: "destructive"
          });
          hasValidationError = true;
        }
      });
    }

    if (hasValidationError) return;

    setIsSubmitting(true);

    try {
      // Ensure all variant properties use field.id as the key
      const formattedVariantProperties: Record<string, string | number | boolean> = {};
      
      // Copy existing properties
      Object.entries(editedVariant.variant_properties || {}).forEach(([key, value]) => {
        formattedVariantProperties[key] = value;
      });
      
      // Ensure all required fields from the category are included
      if (variantCategory) {
        variantCategory.fields?.forEach?.(field => {
          if (!field) return; // Skip if field is null or undefined
          
          // If the field is not in the properties, add it with a default value
          if (!(field.id in formattedVariantProperties)) {
            switch (field.type) {
              case 'string':
                formattedVariantProperties[field.id] = '';
                break;
              case 'number':
                formattedVariantProperties[field.id] = 0;
                break;
              case 'boolean':
                formattedVariantProperties[field.id] = false;
                break;
              case 'file':
                formattedVariantProperties[field.id] = '';
                break;
              default:
                formattedVariantProperties[field.id] = '';
            }
          }
        });
      }
      
      // Create the final variant object
      const finalVariant: ItemVariant = {
        ...editedVariant,
        variant_properties: formattedVariantProperties
      };
      
      // Save the variant
      onSave(finalVariant);
      
      // Close the dialog
      onOpenChange(false);
      
      toast({
        title: "Success",
        description: "Variant updated successfully"
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update variant",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render input field based on field type
  const renderFieldInput = (field: VariantCategoryField) => {
    if (!field) return null;
    const value = editedVariant.variant_properties?.[field.id];
    
    switch (field.type) {
      case 'string':
        return (
          <Input
            id={field.id}
            value={value as string || ''}
            onChange={(e) => handlePropertyChange(field.id, e.target.value)}
            className="col-span-3"
          />
        );
      case 'number':
        return (
          <Input
            id={field.id}
            type="number"
            value={value as number || 0}
            onChange={(e) => handlePropertyChange(field.id, parseFloat(e.target.value))}
            className="col-span-3"
          />
        );
      case 'boolean':
        return (
          <div className="flex items-center col-span-3">
            <Checkbox
              id={field.id}
              checked={Boolean(value)}
              onCheckedChange={(checked) => handlePropertyChange(field.id, Boolean(checked))}
            />
            <label htmlFor={field.id} className="ml-2 text-sm">
              {field.name}
            </label>
          </div>
        );
      case 'file':
        return (
          <div className="col-span-3">
            <Input
              id={field.id}
              type="file"
              onChange={(e) => {
                // For file inputs, we just store the file name for now
                // In a real implementation, you'd handle file uploads
                if (e.target.files && e.target.files.length > 0) {
                  handlePropertyChange(field.id, e.target.files[0].name);
                }
              }}
              className="col-span-3"
            />
            {value && (
              <p className="text-xs text-gray-500 mt-1">Current file: {value}</p>
            )}
          </div>
        );
      default:
        return (
          <Input
            id={field.id}
            value={value as string || ''}
            onChange={(e) => handlePropertyChange(field.id, e.target.value)}
            className="col-span-3"
          />
        );
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>
            Edit {isPackageVariant ? 'Package' : isPlanVariant ? 'Plan' : 'Product'} Variant
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              value={editedVariant.name}
              onChange={(e) => handleChange('name', e.target.value)}
              className="col-span-3"
            />
          </div>
          
          {/* Only show currency and amount fields for product variants */}
          {!isSubscriptionVariant && (
            <>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="currency" className="text-right">
                  Currency
                </Label>
                <Select
                  value={editedVariant.currency}
                  onValueChange={(value) => handleChange('currency', value)}
                >
                  <SelectTrigger id="currency" className="col-span-3">
                    <SelectValue placeholder="Select currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {SUPPORTED_CURRENCIES.map((currency) => (
                      <SelectItem key={currency.code} value={currency.code}>
                        {currency.symbol} {currency.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amount" className="text-right">
                  Amount
                </Label>
                <Input
                  id="amount"
                  type="number"
                  min="0"
                  step="0.01"
                  value={editedVariant.amount}
                  onChange={(e) => handleChange('amount', parseFloat(e.target.value))}
                  className="col-span-3"
                />
              </div>
            </>
          )}

          {/* For plan variants, show a message about pricing */}
          {isPlanVariant && (
            <div className="bg-blue-50 p-3 rounded-md text-sm text-blue-800">
              <p>Pricing for this plan is managed on the plan edit page. Changes made here will only affect variant properties and display settings.</p>
            </div>
          )}

          {/* For package variants, show a message about plans */}
          {isPackageVariant && (
            <div className="bg-blue-50 p-3 rounded-md text-sm text-blue-800">
              <p>This package contains multiple plans that will be resolved during checkout. The specific plan will be selected based on the customer's location and currency.</p>
              <p className="mt-2">To modify the plans in this package, please use the package management interface.</p>
            </div>
          )}
          
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="is_default" className="text-right">
              Default
            </Label>
            <div className="flex items-center col-span-3">
              <Checkbox
                id="is_default"
                checked={editedVariant.is_default || false}
                onCheckedChange={(checked) => handleChange('is_default', Boolean(checked))}
              />
              <label htmlFor="is_default" className="ml-2 text-sm">
                Set as default variant
              </label>
            </div>
          </div>
          
          {/* Category Properties */}
          {variantCategory && (
            <div className="mt-4">
              <h3 className="text-sm font-medium mb-2">Category Properties</h3>
              <div className="mb-4 border p-3 rounded-md">
                <h4 className="font-medium mb-2">{variantCategory.name}</h4>
                <div className="space-y-3">
                  {variantCategory.fields?.map?.(field => (
                    field ? (
                      <div key={field.id} className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor={field.id} className="text-right">
                          {field.name}
                          {field.required && <span className="text-red-500 ml-1">*</span>}
                        </Label>
                        {renderFieldInput(field)}
                      </div>
                    ) : null
                  )) || (
                    <p className="text-sm text-gray-500">No fields defined for this category.</p>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {/* Debug Information */}
          <div className="mt-4 border-t border-gray-200 pt-4">
            <details className="text-sm">
              <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
              <div className="mt-2 space-y-4">
                <div>
                  <h3 className="font-medium mb-1">Current Variant State:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {JSON.stringify(editedVariant, null, 2)}
                  </pre>
                </div>
                <div>
                  <h3 className="font-medium mb-1">Original Variant:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {JSON.stringify(variant, null, 2)}
                  </pre>
                </div>
                {variantCategory && (
                  <div>
                    <h3 className="font-medium mb-1">Variant Category:</h3>
                    <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                      {JSON.stringify(variantCategory, null, 2)}
                    </pre>
                  </div>
                )}
              </div>
            </details>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? "Saving..." : "Save Changes"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 