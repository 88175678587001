import {useParams} from "react-router";
import apiClient, {getFetcher} from "~/lib/apiClient";
import useSWR from "swr";
import {get} from "lodash";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {Input} from "~/components/ui/input";
import {Button} from "~/components/ui/button";
import {H1, H2} from "~/components/ui/typography";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "~/components/ui/select";
import {Card, CardContent, CardHeader} from "~/components/ui/card";
import {Badge} from "~/components/ui/badge";
import {Separator} from "~/components/ui/separator";
import {Checkbox} from "~/components/ui/checkbox";
import {Alert, AlertDescription, AlertTitle} from "~/components/ui/alert";
import {Label} from "~/components/ui/label";
import {CopyIcon, CheckIcon, KeyIcon, EyeIcon, EyeOffIcon, HashIcon, CreditCardIcon, GlobeIcon} from "lucide-react";

interface CheckboxGroupProps {
  label: string;
  options: {label: string; value: string}[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
}

interface Client {
  id: string;
  name: string;
  type: string;
  platform: string | null;
  environment: 'live' | 'test';
  secret: string;
  billing_provider_id: string | null;
  exclusion_rules: {rule: string; value: string}[] | null;
  allowed_origins: string[] | null;
  pending_origins: string[] | null;
  api_keys: any[];
  test_user_jwt: string;
}

interface FormData {
  name: string | null;
  billing_provider_id: string | null;
  exclusion_rules: string;
  allowed_origins: string[];
}

interface BillingProvider {
  id: string;
  name: string;
}

function SecretField({secret}: {secret: string}) {
  const [isVisible, setIsVisible] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  
  const toggleVisibility = () => setIsVisible(!isVisible);
  
  const handleCopy = () => {
    navigator.clipboard.writeText(secret);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  
  const displayValue = isVisible ? secret : '•'.repeat(Math.min(32, secret.length));

  return (
    <div className="flex items-center bg-muted px-3 py-2 rounded-md">
      <code className="text-sm font-mono flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {displayValue}
      </code>
      <div className="flex gap-2">
        <Button variant="ghost" size="sm" onClick={toggleVisibility} className="h-8 w-8 p-0">
          {isVisible ? <EyeOffIcon className="h-3 w-3" /> : <EyeIcon className="h-3 w-3" />}
        </Button>
        <Button variant="ghost" size="sm" onClick={handleCopy} className="h-8 w-8 p-0">
          {copied ? <CheckIcon className="h-3 w-3" /> : <CopyIcon className="h-3 w-3" />}
        </Button>
      </div>
    </div>
  );
}

function CheckboxGroup({label, options, selectedValues, onChange}: CheckboxGroupProps) {
  const handleChange = (value: string, checked: boolean) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter(v => v !== value);
    onChange(newSelectedValues);
  };

  return (
    <div className="space-y-4">
      <Label>{label}</Label>
      <div className="space-y-2">
        {options.map(option => (
          <div key={option.value} className="flex items-center space-x-2">
            <Checkbox
              id={option.value}
              checked={selectedValues.includes(option.value)}
              onCheckedChange={(checked) => handleChange(option.value, checked as boolean)}
            />
            <Label htmlFor={option.value} className="text-sm text-muted-foreground">
              {option.label}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function ClientDetailPage() {
  const {clientId} = useParams<{clientId: string}>();

  const {data: client, error} = useSWR<Client>(`/clients/${clientId}`, getFetcher);
  const {data: billingProviders, error: billingProvidersError} = useSWR<{data: BillingProvider[]}>(`/billing/providers`, getFetcher);

  const [data, setData] = useState<FormData>({
    name: null,
    billing_provider_id: null,
    exclusion_rules: "",
    allowed_origins: [],
  });
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [removedOrigins, setRemovedOrigins] = useState<string[]>([]);

  useEffect(() => {
    if (!client) return;
    setData({
      name: client.name,
      billing_provider_id: client.billing_provider_id,
      exclusion_rules: client.exclusion_rules
        ? client.exclusion_rules.map(rule => rule.value).join(",")
        : "",
      allowed_origins: client.allowed_origins || [],
    });
  }, [client]);

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setIsLoading(true);
    setErrors({});

    const formattedData = {
      ...data,
      exclusion_rules: data.exclusion_rules.split(",").map(domain => ({
        rule: "emailDomainIs",
        value: domain.trim(),
      })),
    };

    apiClient.patch(`/clients/${clientId}`, formattedData)
      .then(() => {
        setIsLoading(false);
        setRemovedOrigins([]);
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
      });
  }

  function getFieldError(fieldName: string) {
    const errorMessages = Object.entries(errors)
      .filter(([key]) => key.startsWith(fieldName))
      .map(([, value]) => value)
      .flat();
    return errorMessages.length > 0 ? errorMessages : undefined;
  }

  function handleAllowedOriginsChange(selectedOrigins: string[]) {
    const newRemovedOrigins = (client?.allowed_origins || []).filter(
      origin => !selectedOrigins.includes(origin)
    );
    setRemovedOrigins(newRemovedOrigins);
    setData({...data, allowed_origins: selectedOrigins});
  }

  if (!client) return <div>Loading...</div>;
  if (error) return <div>Error loading client: {error}</div>;

  const allOrigins = [...(client.allowed_origins || []), ...(client.pending_origins || [])].filter((value, index, self) => self.indexOf(value) === index);
  const isTest = client.environment === 'test';

  return (
    <div className="p-10">
      <div className="flex items-center gap-4 mb-8">
        <Link to="/settings/developers" className="flex items-center">
          <ChevronLeftIcon className="w-6 h-6" />
        </Link>
        <div className="flex items-center gap-3">
          <H1>{client.name}</H1>
          <Badge variant={isTest ? 'secondary' : 'default'} className="uppercase">
            {client.type}
          </Badge>
        </div>
      </div>

      {client.platform && (
        <div className="flex items-center gap-2 mb-6">
          <GlobeIcon className="h-4 w-4" />
          <span className="text-sm text-muted-foreground">{client.platform}</span>
        </div>
      )}

      <div className="flex items-center gap-2 mb-8">
        <CreditCardIcon className={`h-4 w-4 ${client.billing_provider_id ? 'text-green-600' : 'text-orange-600'}`} />
        <span className={`text-sm ${client.billing_provider_id ? 'text-green-600' : 'text-orange-600'}`}>
          {client.billing_provider_id ? 'Connected to billing' : 'No billing connected'}
        </span>
      </div>

      <div className="grid gap-6 lg:grid-cols-2">
        {/* Settings Form */}
        <Card>
          <CardHeader>
            <H2>Settings</H2>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={data.name || ''}
                  onChange={(e) => setData({...data, name: e.target.value})}
                  aria-invalid={!!getFieldError('name')}
                />
                {getFieldError('name')?.map((error, i) => (
                  <p key={i} className="text-sm text-destructive">{error}</p>
                ))}
              </div>

              {billingProviders?.data?.length > 0 && (
                <div className="space-y-2">
                  <Label htmlFor="billing_provider_id">Billing Provider</Label>
                  <Select
                    value={data.billing_provider_id || ''}
                    onValueChange={(value) => setData({...data, billing_provider_id: value})}
                  >
                    <SelectTrigger id="billing_provider_id">
                      <SelectValue placeholder="Select billing provider" />
                    </SelectTrigger>
                    <SelectContent>
                      {billingProviders.data.map((provider) => (
                        <SelectItem key={provider.id} value={provider.id}>
                          {provider.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              {billingProviders?.data?.length === 0 && (
                <Alert variant="warning">
                  <AlertTitle>No billing providers available</AlertTitle>
                </Alert>
              )}

              {billingProvidersError && (
                <Alert variant="destructive">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>Error loading billing providers: {billingProvidersError}</AlertDescription>
                </Alert>
              )}

              <div className="space-y-2">
                <Label htmlFor="exclusion_rules">Exclusion Rules</Label>
                <Input
                  id="exclusion_rules"
                  value={data.exclusion_rules}
                  onChange={(e) => setData({...data, exclusion_rules: e.target.value})}
                  placeholder="Enter email domains separated by commas (e.g., example.com, test.com)"
                  aria-invalid={!!getFieldError('exclusion_rules')}
                />
                {getFieldError('exclusion_rules')?.map((error, i) => (
                  <p key={i} className="text-sm text-destructive">{error}</p>
                ))}
              </div>

              <CheckboxGroup
                label="Allowed Origins"
                options={allOrigins.map(origin => ({label: origin, value: origin}))}
                selectedValues={data.allowed_origins}
                onChange={handleAllowedOriginsChange}
              />

              {removedOrigins.length > 0 && (
                <Alert variant="warning">
                  <AlertTitle>Warning</AlertTitle>
                  <AlertDescription>
                    The following origins will be removed and not added back to pending:
                    {removedOrigins.join(", ")}
                  </AlertDescription>
                </Alert>
              )}

              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            </form>
          </CardContent>
        </Card>

        {/* Credentials Card */}
        <Card>
          <CardHeader>
            <H2>Credentials</H2>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <HashIcon className="h-3 w-3" />
                  <Label>Client ID</Label>
                </div>
                <div className="flex items-center bg-muted px-3 py-2 rounded-md">
                  <code className="text-sm font-mono flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {client.id}
                  </code>
                  <Button variant="ghost" size="sm" onClick={() => navigator.clipboard.writeText(client.id)} className="h-8 w-8 p-0">
                    <CopyIcon className="h-3 w-3" />
                  </Button>
                </div>
              </div>

              <div>
                <div className="flex items-center gap-2 mb-2">
                  <KeyIcon className="h-3 w-3" />
                  <Label>Client Secret</Label>
                </div>
                <SecretField secret={client.secret} />
              </div>

              {client.test_user_jwt && (
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <KeyIcon className="h-3 w-3" />
                    <Label>Test User JWT</Label>
                  </div>
                  <SecretField secret={client.test_user_jwt} />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
