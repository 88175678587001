import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { useToast } from "~/components/ui/use-toast";
import { VariantCategory } from "../types";
import useSWR from 'swr';
import { getFetcher } from '~/lib/apiClient';
import { RadioGroup, RadioGroupItem } from "~/components/ui/radio-group";
import { Label } from "~/components/ui/label";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCategorySelected: (categoryId: string) => void;
  currentCategoryId?: string | null; // ID of the current category applied to the offer
};

export default function CategorySelector({ 
  open, 
  onOpenChange, 
  onCategorySelected,
  currentCategoryId = null
}: Props) {
  const { toast } = useToast();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(currentCategoryId || 'none');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Update selectedCategoryId when currentCategoryId changes
  useEffect(() => {
    if (currentCategoryId) {
      setSelectedCategoryId(currentCategoryId);
    } else {
      setSelectedCategoryId('none');
    }
  }, [currentCategoryId]);

  // Fetch variant categories
  const { data: categoriesResponse } = useSWR<{data: VariantCategory[] }>(
    '/store/variant_categories',
    getFetcher
  );
  
  const allCategories = categoriesResponse?.data || [];

  const handleSubmit = async () => {
    // Allow 'none' selection to clear the category
    if (selectedCategoryId === undefined) {
      toast({
        title: "Validation Error",
        description: "Please select a category or 'No Category'",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);

    try {
      console.log('CategorySelector - Selected category ID:', selectedCategoryId || 'None (clearing category)');
      
      // Call the onCategorySelected callback with the selected category ID
      // If selectedCategoryId is empty, it will clear the category
      onCategorySelected(selectedCategoryId === 'none' ? '' : selectedCategoryId);
      
      // Reset the selected category
      setSelectedCategoryId('none');
      
      // Close the dialog
      onOpenChange(false);
      
      toast({
        title: "Success",
        description: selectedCategoryId ? "Category set successfully" : "Category cleared successfully"
      });
    } catch (error) {
      console.error('Error setting category:', error);
      toast({
        title: "Error",
        description: "Failed to set category",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Select Variant Category for Offer</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <p className="text-sm text-gray-500 mb-4">
            Select a category to apply to all variants in this offer. This will define the fields that all variants must implement.
          </p>
          
          {allCategories.length === 0 ? (
            <div className="text-center p-6 border border-dashed rounded-md">
              <p className="text-gray-500">No categories available.</p>
              <p className="text-sm text-gray-400 mt-1">Create a category first before selecting one for this offer.</p>
            </div>
          ) : (
            <RadioGroup
              value={selectedCategoryId}
              onValueChange={setSelectedCategoryId}
              className="space-y-3"
            >
              {/* Option to clear the category */}
              <div className="flex items-start space-x-2">
                <RadioGroupItem value="none" id="clear-category" className="mt-1" />
                <div className="flex-1">
                  <Label htmlFor="clear-category" className="font-medium text-base">
                    No Category
                  </Label>
                  <div className="mt-1 text-sm text-gray-500">
                    <p>Remove the variant category from this offer. All variants will lose their category properties.</p>
                  </div>
                </div>
              </div>
              
              {allCategories.map((category) => (
                <div key={category.id} className="flex items-start space-x-2">
                  <RadioGroupItem value={category.id} id={category.id} className="mt-1" />
                  <div className="flex-1">
                    <Label htmlFor={category.id} className="font-medium text-base">
                      {category.name}
                      {currentCategoryId === category.id && (
                        <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Current</span>
                      )}
                    </Label>
                    <div className="mt-1 text-sm text-gray-500">
                      <p className="mb-1">Fields:</p>
                      {category.fields && category.fields.length > 0 ? (
                        <ul className="list-disc pl-5 space-y-1">
                          {category.fields.map(field => (
                            field ? (
                              <li key={field.id}>
                                {field.name} 
                                <span className="text-xs ml-1 text-gray-400">
                                  ({field.type}{field.required ? ', required' : ''})
                                </span>
                              </li>
                            ) : null
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-400">No fields defined for this category.</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </RadioGroup>
          )}
          
          {/* Debug Information */}
          <div className="mt-6 border-t border-gray-200 pt-4">
            <details className="text-sm">
              <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
              <div className="mt-2 space-y-4">
                <div>
                  <h3 className="font-medium mb-1">Selected Category ID:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {selectedCategoryId || 'None'}
                  </pre>
                </div>
                <div>
                  <h3 className="font-medium mb-1">Current Category ID:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {currentCategoryId || 'None'}
                  </pre>
                </div>
                <div>
                  <h3 className="font-medium mb-1">Available Categories:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {JSON.stringify(allCategories, null, 2)}
                  </pre>
                </div>
              </div>
            </details>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={isSubmitting || !selectedCategoryId || allCategories.length === 0}
          >
            {isSubmitting ? 'Setting...' : 'Set Category'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 