import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { useToast } from "~/components/ui/use-toast";
import { VariantCategory, VariantCategoryField } from "../types";
import { Checkbox } from "~/components/ui/checkbox";
import useSWR, { mutate } from 'swr';
import { getFetcher } from '~/lib/apiClient';
import apiClient from '~/lib/apiClient';
import { PlusCircle, Trash2, Edit, Save } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { Switch } from "~/components/ui/switch";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  categoryId?: string; // If provided, edit this category; otherwise, create a new one
  onSuccess?: () => void;
};

// Field type options
const FIELD_TYPES = [
  { value: 'string', label: 'Text' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'file', label: 'File Upload' }
];

// Add toCamelCase utility function
const toCamelCase = (str: string): string => {
  return str
    .trim()
    .replace(/[\s_-]+(.)/g, (_, c) => c.toUpperCase())
    .replace(/^(.)/, c => c.toLowerCase())
    .replace(/[^a-zA-Z0-9]/g, '');
};

export default function CategoryEditor({ 
  open, 
  onOpenChange, 
  categoryId,
  onSuccess
}: Props) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState<Partial<VariantCategory>>({
    name: '',
    fields: []
  });
  
  // Ensure category.fields is always an array
  useEffect(() => {
    if (!category.fields) {
      setCategory(prev => ({
        ...prev,
        fields: []
      }));
    }
  }, [category.fields]);
  
  const [newField, setNewField] = useState<Partial<VariantCategoryField>>({
    name: '',
    type: 'string',
    required: false
  });
  const [editingFieldIndex, setEditingFieldIndex] = useState<number | null>(null);

  // Fetch categories to get the current category if editing
  const { data: categoriesResponse, mutate: refreshCategories } = useSWR<{data: VariantCategory[] }>(
    '/store/variant_categories',
    getFetcher
  );
  
  const allCategories = categoriesResponse?.data || [];

  // Load the category data if editing an existing category
  useEffect(() => {
    if (categoryId && allCategories.length > 0) {
      const existingCategory = allCategories.find(c => c.id === categoryId);
      if (existingCategory) {
        // Ensure fields are properly formatted
        const formattedFields = (existingCategory.fields || []).map(field => ({
          id: field.id,
          name: field.name,
          type: field.type as 'string' | 'number' | 'boolean' | 'file',
          required: field.required === true,
          ...(field.options ? { options: field.options } : {})
        }));

        setCategory({
          id: existingCategory.id,
          name: existingCategory.name,
          fields: formattedFields
        });
      }
    } else {
      // Reset for new category
      setCategory({
        name: '',
        fields: []
      });
    }
  }, [categoryId, allCategories]);

  // Reset the form when the dialog opens/closes
  useEffect(() => {
    if (!open) {
      setNewField({
        name: '',
        type: 'string',
        required: false
      });
      setEditingFieldIndex(null);
    }
  }, [open]);

  const handleCategoryNameChange = (name: string) => {
    setCategory(prev => ({
      ...prev,
      name
    }));
  };

  const handleFieldChange = (field: keyof VariantCategoryField, value: any) => {
    if (field === 'name') {
      // Convert field name to camelCase
      const camelCaseName = toCamelCase(value);
      setNewField(prev => ({
        ...prev,
        [field]: camelCaseName
      }));
    } else {
      setNewField(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleAddField = () => {
    if (!newField.name) {
      toast({
        title: "Validation Error",
        description: "Field name is required",
        variant: "destructive"
      });
      return;
    }

    // Ensure the field name is in camelCase
    const camelCaseName = toCamelCase(newField.name);
    if (camelCaseName !== newField.name) {
      toast({
        title: "Field Name Format",
        description: "Field name has been converted to camelCase format",
      });
    }

    // Ensure the field type is one of the allowed values
    const fieldType = newField.type as 'string' | 'number' | 'boolean' | 'file';
    if (!['string', 'number', 'boolean', 'file'].includes(fieldType)) {
      toast({
        title: "Validation Error",
        description: "Invalid field type",
        variant: "destructive"
      });
      return;
    }

    if (editingFieldIndex !== null) {
      // Update existing field
      const updatedFields = [...(category.fields || [])];
      updatedFields[editingFieldIndex] = {
        ...updatedFields[editingFieldIndex],
        name: camelCaseName,
        type: fieldType,
        required: newField.required === true,
        id: updatedFields[editingFieldIndex].id
      } as VariantCategoryField;

      setCategory(prev => ({
        ...prev,
        fields: updatedFields
      }));
    } else {
      // Add new field
      const newFieldWithId: VariantCategoryField = {
        id: `temp-${Date.now()}`, // Temporary ID, will be replaced by the server
        name: camelCaseName,
        type: fieldType,
        required: newField.required === true
      };

      setCategory(prev => ({
        ...prev,
        fields: [...(prev.fields || []), newFieldWithId]
      }));
    }

    // Reset the new field form
    setNewField({
      name: '',
      type: 'string',
      required: false
    });
    setEditingFieldIndex(null);
  };

  const handleEditField = (index: number) => {
    const field = category.fields?.[index];
    if (!field) return;

    setNewField({
      name: field.name,
      type: field.type,
      required: field.required
    });
    setEditingFieldIndex(index);
  };

  const handleRemoveField = (index: number) => {
    const updatedFields = [...(category.fields || [])];
    updatedFields.splice(index, 1);

    setCategory(prev => ({
      ...prev,
      fields: updatedFields
    }));

    // If we were editing this field, reset the form
    if (editingFieldIndex === index) {
      setNewField({
        name: '',
        type: 'string',
        required: false
      });
      setEditingFieldIndex(null);
    }
  };

  const handleSubmit = async () => {
    if (!category.name) {
      toast({
        title: "Validation Error",
        description: "Category name is required",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Ensure fields is properly included in the request payload
      // Make sure each field has the required properties
      const formattedFields = (category.fields || []).map(field => ({
        id: field.id,
        name: field.name,
        type: field.type,
        required: field.required === true, // Ensure it's a boolean
        ...(field.options ? { options: field.options } : {}) // Include options if present
      }));

      const payload = {
        name: category.name,
        fields: formattedFields
      };

      console.log('Submitting category with payload:', payload);
      
      let response;
      
      if (categoryId) {
        // Update existing category
        response = await apiClient.put(`/store/variant_categories/${categoryId}`, payload);
        toast({
          title: "Success",
          description: "Category updated successfully"
        });
      } else {
        // Create new category
        response = await apiClient.post('/store/variant_categories', payload);
        toast({
          title: "Success",
          description: "Category created successfully"
        });
      }
      
      console.log('Category response:', response.data);
      
      // Refresh the categories list
      mutate('/store/variant_categories');
      
      // Close the dialog
      onOpenChange(false);
      
      // Call the success callback if provided
      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      console.error('Error submitting category:', error);
      
      if (error.response?.status === 422 && error.response.data.errors) {
        // Handle validation errors
        const validationErrors = error.response.data.errors;
        
        // Display the first error message
        const firstError = Object.values(validationErrors)[0];
        toast({
          title: "Validation Error",
          description: Array.isArray(firstError) ? firstError[0] : firstError,
          variant: "destructive"
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to save category",
          variant: "destructive"
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>{categoryId ? 'Edit Category' : 'Create New Category'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="category-name" className="text-right">
              Category Name
            </Label>
            <Input
              id="category-name"
              value={category.name}
              onChange={(e) => handleCategoryNameChange(e.target.value)}
              className="col-span-3"
            />
          </div>

          <div className="mt-4">
            <h3 className="text-sm font-medium mb-2">Fields</h3>
            
            {/* Field list */}
            {category.fields && category.fields.length > 0 ? (
              <div className="space-y-2 mb-4">
                {category.fields.map((field, index) => (
                  <Card key={field.id} className="p-0">
                    <CardContent className="p-3 flex justify-between items-center">
                      <div>
                        <p className="font-medium">{field.name}</p>
                        <div className="flex items-center gap-2 text-xs text-gray-500">
                          <span>Type: {field.type}</span>
                          {field.required && <span className="text-red-500">Required</span>}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Button 
                          variant="outline" 
                          size="sm"
                          onClick={() => handleEditField(index)}
                        >
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button 
                          variant="destructive" 
                          size="sm"
                          onClick={() => handleRemoveField(index)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <div className="text-center p-4 border border-dashed rounded mb-4">
                <p className="text-gray-500">No fields added yet.</p>
                <p className="text-sm text-gray-400">Add fields to define the properties of this category.</p>
              </div>
            )}

            {/* Add/Edit field form */}
            <Card>
              <CardHeader className="pb-2">
                <CardTitle className="text-base">{editingFieldIndex !== null ? 'Edit Field' : 'Add New Field'}</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-3">
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="field-name" className="text-right">
                      Field Name
                    </Label>
                    <Input
                      id="field-name"
                      value={newField.name}
                      onChange={(e) => handleFieldChange('name', e.target.value)}
                      className="col-span-3"
                    />
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="field-type" className="text-right">
                      Field Type
                    </Label>
                    <Select
                      value={newField.type}
                      onValueChange={(value) => handleFieldChange('type', value)}
                    >
                      <SelectTrigger className="col-span-3">
                        <SelectValue placeholder="Select field type" />
                      </SelectTrigger>
                      <SelectContent>
                        {FIELD_TYPES.map((type) => (
                          <SelectItem key={type.value} value={type.value}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="field-required" className="text-right">
                      Required
                    </Label>
                    <div className="col-span-3 flex items-center">
                      <Switch
                        id="field-required"
                        checked={newField.required}
                        onCheckedChange={(checked) => handleFieldChange('required', checked)}
                      />
                      <Label htmlFor="field-required" className="ml-2">
                        {newField.required ? 'Yes' : 'No'}
                      </Label>
                    </div>
                  </div>
                  <div className="flex justify-end mt-2">
                    {editingFieldIndex !== null && (
                      <Button
                        type="button"
                        variant="outline"
                        className="mr-2"
                        onClick={() => {
                          setNewField({
                            name: '',
                            type: 'string',
                            required: false
                          });
                          setEditingFieldIndex(null);
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      type="button"
                      onClick={handleAddField}
                    >
                      {editingFieldIndex !== null ? (
                        <>
                          <Save className="h-4 w-4 mr-1" />
                          Update Field
                        </>
                      ) : (
                        <>
                          <PlusCircle className="h-4 w-4 mr-1" />
                          Add Field
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button type="button" onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : (categoryId ? 'Update Category' : 'Create Category')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 