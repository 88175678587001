import * as React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Card } from "~/components/ui/card";

type PaginatedResponse<T> = {
  data: T[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
};

type Transaction = {
  id: string;
  intent: 'order' | 'subscription';
  current_state: 'created' | 'paid' | 'failed' | 'refunded';
  amount_total: number;
  currency: string;
  created_at: string;
  updated_at: string;
  customer?: {
    name: string | null;
    email: string | null;
  } | null;
};

function TransactionStatusBadge({ status }: { status: string }) {
  const variant = React.useMemo(() => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'bg-green-100 text-green-700';
      case 'failed':
        return 'bg-red-100 text-red-700';
      case 'refunded':
        return 'bg-yellow-100 text-yellow-700';
      case 'pending':
        return 'bg-gray-100 text-gray-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  }, [status]);

  const label = React.useMemo(() => {
    switch (status?.toLowerCase()) {
      case 'paid':
        return 'Paid';
      case 'failed':
        return 'Failed';
      case 'refunded':
        return 'Refunded';
      case 'created':
        return 'Pending';
      default:
        return status;
    }
  }, [status]);

  return (
    <Badge className={variant}>
      {label}
    </Badge>
  );
}

export default function TransactionsListPage() {
  const { data, error, isLoading } = useSWR<PaginatedResponse<Transaction>>(
    '/store/transactions',
    getFetcher
  );

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Transactions</h1>
          {data && (
            <p className="text-sm text-muted-foreground mt-1">
              Showing {data.meta.from} to {data.meta.to} of {data.meta.total} transactions
            </p>
          )}
        </div>
        <div className="flex gap-2">
          {/* Add any action buttons here */}
        </div>
      </div>

      
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>ID</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right">Amount</TableHead>
                <TableHead>Created</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {isLoading ? (
                Array.from({ length: 5 }).map((_, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <div className="h-4 w-32 bg-muted rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-4 w-24 bg-muted rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-4 w-16 bg-muted rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-4 w-24 bg-muted rounded animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-4 w-32 bg-muted rounded animate-pulse"></div>
                    </TableCell>
                  </TableRow>
                ))
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-8 text-muted-foreground">
                    Failed to load transactions. Please try again.
                  </TableCell>
                </TableRow>
              ) : data?.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-8 text-muted-foreground">
                    No transactions found.
                  </TableCell>
                </TableRow>
              ) : (
                data?.data.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>
                      <Link 
                        to={`/store/transactions/${transaction.id}`}
                        className="text-primary hover:underline"
                      > 
                        {transaction.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Badge variant={transaction.intent === 'subscription' ? 'secondary' : 'outline'}>
                        {transaction.intent === 'subscription' ? 'Subscription' : 'Order'}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <TransactionStatusBadge status={transaction.current_state} />
                    </TableCell>
                    <TableCell className="text-right">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(transaction.amount_total/100)}
                    </TableCell>
                    <TableCell className="text-muted-foreground">
                      {formatDistanceToNow(new Date(transaction.created_at), { addSuffix: true })}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
    

      {data && data.meta.last_page > 1 && (
        <div className="flex items-center justify-between">
          <p className="text-sm text-muted-foreground">
            Page {data.meta.current_page} of {data.meta.last_page}
          </p>
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              disabled={!data.links.prev}
              onClick={() => {
                // Add pagination handling
              }}
            >
              <ChevronLeft className="h-4 w-4 mr-2" />
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              disabled={!data.links.next}
              onClick={() => {
                // Add pagination handling
              }}
            >
              Next
              <ChevronRight className="h-4 w-4 ml-2" />
            </Button>
          </div>
        </div>
      )}

      {/* Debug Information */}
      <div className="mt-8 border-t pt-4">
        <details className="text-sm">
          <summary className="font-medium text-muted-foreground cursor-pointer">Debug Information</summary>
          <div className="mt-2">
            <pre className="bg-muted p-4 rounded-md overflow-auto">
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        </details>
      </div>
    </div>
  );
}
