import { Card } from "~/components/ui/card";
import { Button } from "~/components/ui/button";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { format } from "date-fns";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "~/components/ui/table";

type Transaction = {
  id: string;
  intent: 'order' | 'subscription';
  current_state: 'created' | 'paid' | 'failed' | 'refunded';
  amount_total: string;
  currency: string;
  customer: {
    name: string;
    email: string;
  } | null;
  completed_at: string | null;
  created_at: string;
};

type PaginatedResponse<T> = {
  data: T[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
};

function formatCurrency(amount: string | number | undefined, currency: string) {
  if (!amount) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD'
  }).format(Number(amount)/100);
}

function TransactionStatusBadge({ status }: { status: Transaction['current_state'] }) {
  const variants: Record<Transaction['current_state'], string> = {
    paid: 'text-green-700 bg-green-100',
    failed: 'text-red-700 bg-red-100',
    refunded: 'text-yellow-700 bg-yellow-100',
    created: 'text-gray-700 bg-gray-100'
  };

  const labels: Record<Transaction['current_state'], string> = {
    paid: 'Paid',
    failed: 'Failed',
    refunded: 'Refunded',
    created: 'Pending'
  };

  return (
    <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${variants[status]}`}>
      {labels[status]}
    </span>
  );
}

type Props = {
  offerId: string;
};

export default function TransactionsTab({ offerId }: Props) {
  const { data, error, isLoading } = useSWR<PaginatedResponse<Transaction>>(
    `/store/offers/${offerId}/transactions`,
    getFetcher
  );

  return (
    <div>
      <div >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">Transactions</h2>
          <div className="flex gap-2">
            {/* Add any action buttons here if needed */}
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Customer</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>Date</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="flex justify-center py-6">
                    <div className="space-y-4 w-full max-w-[800px]">
                      {[...Array(3)].map((_, i) => (
                        <div key={i} className="h-12 bg-muted rounded animate-pulse" />
                      ))}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-8 text-muted-foreground">
                  Failed to load transactions. Please try again.
                </TableCell>
              </TableRow>
            ) : data?.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-8 text-muted-foreground">
                  No transactions found for this offer.
                </TableCell>
              </TableRow>
            ) : (
              data?.data.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>
                    <div>
                      <div className="font-medium">
                        {transaction.customer?.name || 'Anonymous'}
                      </div>
                      {transaction.customer?.email && (
                        <div className="text-sm text-muted-foreground">
                          {transaction.customer.email}
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <TransactionStatusBadge status={transaction.current_state} />
                  </TableCell>
                  <TableCell>
                    <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-muted">
                      {transaction.intent === 'subscription' ? 'Subscription' : 'One-time'}
                    </span>
                  </TableCell>
                  <TableCell>
                    {formatCurrency(transaction.amount_total, transaction.currency)}
                  </TableCell>
                  <TableCell>
                    {transaction.completed_at ? (
                      format(new Date(transaction.completed_at), 'MMM d, yyyy')
                    ) : (
                      <span className="text-muted-foreground">-</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button variant="outline" size="sm" asChild>
                      <Link to={`/store/transactions/${transaction.id}`}>
                        View
                      </Link>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {data && data.meta.last_page > 1 && (
          <div className="flex justify-between items-center mt-4 pt-4 border-t">
            <div className="text-sm text-muted-foreground">
              Showing {data.meta.from} to {data.meta.to} of {data.meta.total} results
            </div>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                disabled={!data.links.prev}
                onClick={() => {
                  // Add pagination handling
                }}
              >
                Previous
              </Button>
              <Button
                variant="outline"
                size="sm"
                disabled={!data.links.next}
                onClick={() => {
                  // Add pagination handling
                }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 