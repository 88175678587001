import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { Loader2, Trash2 } from "lucide-react";
import { useState } from "react";
import apiClient from "~/lib/apiClient";
import { H1 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui/alert-dialog";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface Agent {
  id: string;
  lookup_key: string;
  created_at: string;
  last_seen_at: string;
  status: string;
}

export default function AgentsListPage() {
  const { data: agents, error, isLoading, mutate } = useSWR<{ data: Agent[] }>('/account/agents', getFetcher);
  const [agentToDelete, setAgentToDelete] = useState<Agent | null>(null);

  const handleDelete = async (agent: Agent) => {
    try {
      await apiClient.delete(`/account/agents/${agent.lookup_key}`);
      await mutate();
      setAgentToDelete(null);
    } catch (error) {
      console.error('Error deleting agent:', error);
    }
  };

  if (error) {
    return (
      <OrganizationLayout>
        <div className="rounded-md bg-destructive/10 p-4">
          <p className="text-sm text-destructive">
            Error loading agents: {error.message}
          </p>
        </div>
      </OrganizationLayout>
    );
  }

  if (isLoading) {
    return (
      <OrganizationLayout>
        <div className="flex items-center justify-center p-8">
          <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
        </div>
      </OrganizationLayout>
    );
  }

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div>
          <H1>Agents</H1>
          <p className="text-muted-foreground mt-2">
            Manage your organization's agents
          </p>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Agent ID</TableHead>
                <TableHead>Lookup Key</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Last Seen</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {agents?.data.map((agent) => (
                <TableRow key={agent.id}>
                  <TableCell>{agent.id}</TableCell>
                  <TableCell>{agent.lookup_key}</TableCell>
                  <TableCell>
                    <Badge
                      variant={agent.status === 'active' ? 'default' : 'secondary'}
                    >
                      {agent.status}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <div className="text-sm">
                      {new Date(agent.created_at).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {new Date(agent.created_at).toLocaleTimeString()}
                    </div>
                  </TableCell>
                  <TableCell>
                    {agent.last_seen_at ? (
                      <>
                        <div className="text-sm">
                          {new Date(agent.last_seen_at).toLocaleDateString()}
                        </div>
                        <div className="text-sm text-muted-foreground">
                          {new Date(agent.last_seen_at).toLocaleTimeString()}
                        </div>
                      </>
                    ) : (
                      <span className="text-sm text-muted-foreground">Never</span>
                    )}
                  </TableCell>
                  <TableCell>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button
                          variant="destructive"
                          size="sm"
                          className="h-8 w-8 p-0"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Agent</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete this agent? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => handleDelete(agent)}
                          >
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </OrganizationLayout>
  );
} 
