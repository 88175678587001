import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import { formatDateTime } from "~/utils/dateTime";
import { H1 } from "~/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface Attribution {
  id: string;
  workflow_id: string;
  created_at: string;
  environment: 'test' | 'live' | 'unknown';
  paywall: {
    name: string;
  };
  customer: {
    reference_id: string;
  };
  proceeds_amount_gross: {
    amount: number;
  };
  proceeds_amount_net: {
    amount: number;
  };
  data: Record<string, any>;
  purchase: Record<string, any>;
}

interface AttributionsResponse {
  data: Attribution[];
}

export default function AttributionsListPage() {
  const { data: collection, error } = useSWR<AttributionsResponse>('/convert/attributions', getFetcher);

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div>
          <H1>Attributions</H1>
          <p className="text-muted-foreground mt-2">
            Track and monitor payment attributions
          </p>
        </div>

        {collection && (
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID</TableHead>
                  <TableHead>Paywall</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Gross</TableHead>
                  <TableHead>Net</TableHead>
                  <TableHead>Data</TableHead>
                  <TableHead>Purchase</TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {collection.data.map(attribution => (
                  <TableRow
                    key={attribution.id}
                    className={
                      attribution.environment === 'unknown'
                        ? 'bg-muted'
                        : attribution.environment === 'live'
                        ? 'bg-green-50'
                        : 'bg-yellow-50'
                    }
                  >
                    <TableCell>
                      <div>{attribution.id}</div>
                      <div className="text-sm text-muted-foreground">
                        {formatDateTime(attribution.created_at)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <a
                        href={`/convert/workflows/${attribution.workflow_id}`}
                        className="text-primary hover:underline"
                      >
                        {attribution.paywall.name}
                      </a>
                    </TableCell>
                    <TableCell>{attribution.customer.reference_id}</TableCell>
                    <TableCell>{attribution.proceeds_amount_gross.amount}</TableCell>
                    <TableCell>{attribution.proceeds_amount_net.amount}</TableCell>
                    <TableCell>
                      <pre className="text-sm bg-muted p-2 rounded-md overflow-x-auto">
                        {JSON.stringify(attribution.data, null, 2)}
                      </pre>
                    </TableCell>
                    <TableCell>
                      <div className="max-w-[300px] overflow-hidden">
                        <pre className="text-sm bg-muted p-2 rounded-md overflow-x-auto">
                          {JSON.stringify(attribution.purchase, null, 2)}
                        </pre>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </OrganizationLayout>
  );
}

