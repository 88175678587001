import { useState } from "react";
import { toast } from "react-toastify";
import { Card } from "~/components/ui/card";
import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { RadioGroup, RadioGroupItem } from "~/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "~/components/ui/tabs";
import { Webhook, Eye, Code2 } from "lucide-react";
import { Offer } from "../types";
import { WebhookTestDialog } from "./WebhookTestDialog";
import JSONInput from "~/components/form/JSONInput";
import ViewEditor from "~/components/view-editor/ViewEditor";
import bonjoroStages from "~/stage-json/bonjoro-stages";

// This should be imported from your template directory
// const bonjoroStages = []; // Replace with actual import when available

const PROVISIONING_MODE_OPTIONS = [
  { id: 'vendor', name: 'Vendor' },
  { id: 'client', name: 'Client' },
] as const;

const CHECKOUT_MODE_OPTIONS = [
  { id: 'local' as const, name: 'Local Checkout', disabled: false },
  { id: 'external_redirect' as const, name: 'External Redirect', disabled: true },
  { id: 'hybrid' as const, name: 'Hybrid', disabled: true },
];

const PROVISIONING_ACTION_OPTIONS = [
  { id: 'purchase', name: 'Purchase' },
  { id: 'upgrade', name: 'Upgrade' },
  { id: 'expansion', name: 'Expansion' },
] as const;

interface Props {
  offer: Offer;
  updateOffer: (updates: Partial<Offer>) => void;
  errors: Record<string, string[]>;
  isSubmitting: boolean;
  INTENT_OPTIONS: Array<{ id: 'order' | 'subscription'; name: string }>;
  STATUS_OPTIONS: Array<{ id: 'draft' | 'published'; name: string }>;
}

type IntentType = 'order' | 'subscription';
type StatusType = 'draft' | 'published';
type CheckoutModeType = 'local' | 'external_redirect' | 'hybrid';
type ProvisioningActionType = 'purchase' | 'upgrade' | 'expansion';

export default function SettingsTab({ 
  offer, 
  updateOffer, 
  errors, 
  isSubmitting,
  INTENT_OPTIONS,
  STATUS_OPTIONS 
}: Props) {
  const [isWebhookDialogOpen, setIsWebhookDialogOpen] = useState(false);
  const [viewJson, setViewJson] = useState<string>(JSON.stringify(offer.view || [], null, 2));
  const [jsonError, setJsonError] = useState<string | null>(null);

  const handleJsonChange = (value: object | Array<any>) => {
    try {
      const stringified = JSON.stringify(value, null, 2);
      setViewJson(stringified);
      setJsonError(null);
      
      // Automatically update the offer view when JSON is changed
      // Ensure value is an array before updating the offer
      if (Array.isArray(value)) {
        updateOffer({ view: value });
      } else {
        setJsonError("View configuration must be an array");
      }
    } catch (err) {
      setJsonError("Invalid JSON format");
    }
  };

  const handleSaveJson = () => {
    try {
      const parsedJson = JSON.parse(viewJson);
      updateOffer({ view: parsedJson });
      setJsonError(null);
      toast.success("View configuration updated");
    } catch (err) {
      setJsonError("Invalid JSON format");
      toast.error("Failed to save: Invalid JSON format");
    }
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="space-y-8">
        <h2 className="text-lg font-semibold">Offer Settings</h2>
        
        <div className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={offer.name}
              onChange={(e) => updateOffer({ name: e.target.value })}
              disabled={isSubmitting}
              aria-invalid={errors.name ? "true" : undefined}
            />
            {errors.name && (
              <p className="text-sm text-destructive">{errors.name[0]}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label>Checkout Intent</Label>
            <RadioGroup
              value={offer.checkout_intent as IntentType}
              onValueChange={(value: IntentType) => updateOffer({ checkout_intent: value })}
              disabled={isSubmitting}
            >
              <div className="space-y-2">
                {INTENT_OPTIONS.map((option) => (
                  <div key={option.id} className="flex items-center space-x-2">
                    <RadioGroupItem value={option.id} id={`intent-${option.id}`} />
                    <Label htmlFor={`intent-${option.id}`}>{option.name}</Label>
                  </div>
                ))}
              </div>
            </RadioGroup>
            {errors.checkout_intent && (
              <p className="text-sm text-destructive">{errors.checkout_intent[0]}</p>
            )}
          </div>

          {offer.checkout_intent === 'subscription' && (
            <div className="space-y-2">
              <Label>Provisioning Action</Label>
              <RadioGroup
                value={offer.provisioning_action as ProvisioningActionType}
                onValueChange={(value: ProvisioningActionType) => updateOffer({ provisioning_action: value })}
                disabled={isSubmitting}
              >
                <div className="space-y-2">
                  {PROVISIONING_ACTION_OPTIONS.map((option) => (
                    <div key={option.id} className="flex items-center space-x-2">
                      <RadioGroupItem value={option.id} id={`provisioning-action-${option.id}`} />
                      <Label htmlFor={`provisioning-action-${option.id}`}>{option.name}</Label>
                    </div>
                  ))}
                </div>
              </RadioGroup>
              {errors.provisioning_action && (
                <p className="text-sm text-destructive">{errors.provisioning_action[0]}</p>
              )}
            </div>
          )}

          <div className="space-y-2">
            <Label>Status</Label>
            <RadioGroup
              value={offer.status as StatusType}
              onValueChange={(value: StatusType) => updateOffer({ status: value })}
              disabled={isSubmitting}
            >
              <div className="space-y-2">
                {STATUS_OPTIONS.map((option) => (
                  <div key={option.id} className="flex items-center space-x-2">
                    <RadioGroupItem value={option.id} id={`status-${option.id}`} />
                    <Label htmlFor={`status-${option.id}`}>{option.name}</Label>
                  </div>
                ))}
              </div>
            </RadioGroup>
            {errors.status && (
              <p className="text-sm text-destructive">{errors.status[0]}</p>
            )}
          </div>
        </div>

        <div className="border-t pt-6">
          <h3 className="text-base font-medium mb-4">Checkout Configuration</h3>
          
          <div className="space-y-2">
            <Label>Checkout Mode</Label>
            <RadioGroup
              value={offer.checkout_mode as CheckoutModeType}
              onValueChange={(value: CheckoutModeType) => updateOffer({ checkout_mode: value })}
              disabled={isSubmitting}
            >
              <div className="space-y-2">
                {CHECKOUT_MODE_OPTIONS.map((option) => (
                  <div key={option.id} className="flex items-center space-x-2">
                    <RadioGroupItem 
                      value={option.id} 
                      id={`checkout-${option.id}`}
                      disabled={option.disabled}
                    />
                    <Label 
                      htmlFor={`checkout-${option.id}`}
                      className={option.disabled ? "text-muted-foreground" : ""}
                    >
                      {option.name}
                      {option.disabled && (
                        <span className="ml-2 text-xs text-muted-foreground">
                          (Coming soon)
                        </span>
                      )}
                    </Label>
                  </div>
                ))}
              </div>
            </RadioGroup>
            {errors.checkout_mode && (
              <p className="text-sm text-destructive">{errors.checkout_mode[0]}</p>
            )}
          </div>
        </div>

        <div className="border-t pt-6">
          <h3 className="text-base font-medium mb-4">Provisioning Settings</h3>
          
          <div className="mb-4">
            <p className="text-sm text-muted-foreground">Provisioning Type</p>
            <p className="text-sm">Webhook</p>
            <p className="text-xs text-muted-foreground mt-1">
              Provisioning is handled via webhooks. Workflow support coming soon.
            </p>
          </div>

          <div className="flex gap-3 items-end">
            <div className="flex-grow space-y-2">
              <Label htmlFor="webhook-url">Transaction Webhook URL</Label>
              <Input
                id="webhook-url"
                value={offer.transaction_webhook_url || ''}
                onChange={(e) => updateOffer({ transaction_webhook_url: e.target.value })}
                placeholder="https://example.com/webhook"
                disabled={isSubmitting}
                aria-invalid={errors.transaction_webhook_url ? "true" : undefined}
              />
              {errors.transaction_webhook_url && (
                <p className="text-sm text-destructive">{errors.transaction_webhook_url[0]}</p>
              )}
            </div>
            <Button 
              variant="outline"
              disabled={!offer.transaction_webhook_url || isSubmitting}
              onClick={() => setIsWebhookDialogOpen(true)}
            >
              <Webhook className="w-4 h-4 mr-2" />
              Test Webhook
            </Button>
          </div>
        </div>

        <div className="border-t pt-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-base font-medium">View Configuration</h3>
            <Button 
              variant="outline"
              size="sm"
              onClick={() => {
                updateOffer({
                  view: bonjoroStages
                });
                setViewJson(JSON.stringify(bonjoroStages, null, 2));
              }}
            >
              Use Default Template
            </Button>
          </div>

          <Tabs defaultValue="visual" className="space-y-4">
            <TabsList>
              <TabsTrigger value="visual">
                <Eye className="w-4 h-4 mr-2" />
                Visual Editor
              </TabsTrigger>
              <TabsTrigger value="json">
                <Code2 className="w-4 h-4 mr-2" />
                JSON Editor
              </TabsTrigger>
            </TabsList>

            <TabsContent value="visual">
              <ViewEditor
                value={Array.isArray(offer.view) ? offer.view : []}
                onChange={(value) => {
                  updateOffer({ view: value });
                  setViewJson(JSON.stringify(value, null, 2));
                }}
              />
              {errors.view && (
                <p className="text-sm text-destructive mt-2">{errors.view[0]}</p>
              )}
            </TabsContent>

            <TabsContent value="json">
              <div className="space-y-4">
                <JSONInput
                  value={JSON.parse(viewJson)}
                  onChange={handleJsonChange}
                  label="View Configuration"
                  errors={jsonError ? [jsonError] : undefined}
                  rows={15}
                />
                <div className="flex justify-end">
                  <Button 
                    onClick={handleSaveJson}
                    disabled={!!jsonError || isSubmitting}
                  >
                    Apply JSON
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>

        {/* Validation Errors Summary */}
        {Object.keys(errors).length > 0 && (
          <div className="rounded-md bg-destructive/10 p-4">
            <p className="text-sm font-medium text-destructive mb-2">Please correct the following errors:</p>
            <ul className="list-disc pl-5 text-sm text-destructive">
              {Object.entries(errors).map(([field, messages]) => (
                messages.map((message, i) => (
                  <li key={`${field}-${i}`}>{message}</li>
                ))
              ))}
            </ul>
          </div>
        )}

        <WebhookTestDialog 
          offerId={offer.id}
          isOpen={isWebhookDialogOpen}
          onClose={() => setIsWebhookDialogOpen(false)}
        />
      </form>
    </div>
  );
} 