import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { Label } from "~/components/ui/label";
import { Plus, Edit } from "lucide-react";
import { VariantCategory } from "../types";
import { useToast } from "~/components/ui/use-toast";
import ChargeDialog from './ChargeDialog';
import { Charge, Product } from '~/types/catalog';

// Define a local interface for the charge dialog
interface ChargeFormData {
  id?: string | number;
  name: string;
  amount: string;
  currency: string;
  type: string;
  mode: string;
}

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  product: Product;
  onConfirm: (product: Product, selectedCharges: (string | number)[]) => void;
  variantCategory?: VariantCategory | null;
};

export default function ProductChargeSelector({ 
  open, 
  onOpenChange, 
  product,
  onConfirm,
  variantCategory = null
}: Props) {
  const { toast } = useToast();
  const [selectedCharges, setSelectedCharges] = useState<(string | number)[]>([]);
  const [isChargeDialogOpen, setIsChargeDialogOpen] = useState(false);
  const [editingCharge, setEditingCharge] = useState<ChargeFormData | null>(null);

  // Reset selected charges when the product changes
  useEffect(() => {
    setSelectedCharges([]);
  }, [product]);

  const handleToggleCharge = (chargeId: string | number) => {
    if (selectedCharges.includes(chargeId)) {
      setSelectedCharges(selectedCharges.filter(id => id !== chargeId));
    } else {
      setSelectedCharges([...selectedCharges, chargeId]);
    }
  };

  const handleConfirm = () => {
    if (selectedCharges.length === 0) {
      toast({
        title: "No charges selected",
        description: "Please select at least one charge or create a new one",
        variant: "destructive"
      });
      return;
    }
    
    onConfirm(product, selectedCharges);
    onOpenChange(false);
  };

  const handleCreateCharge = () => {
    setEditingCharge(null);
    setIsChargeDialogOpen(true);
  };

  const handleEditCharge = (charge: any) => {
    // Format the charge for the dialog
    const formattedCharge: ChargeFormData = {
      id: charge.id,
      name: charge.name,
      amount: (parseFloat(charge.amount) / 100).toString(),
      currency: charge.currency,
      type: charge.type || 'standard',
      mode: charge.mode || 'recurring'
    };
    
    setEditingCharge(formattedCharge);
    setIsChargeDialogOpen(true);
  };

  const handleChargeDialogClose = () => {
    setIsChargeDialogOpen(false);
    setEditingCharge(null);
  };

  const handleChargeSubmit = (charge: ChargeFormData) => {
    // In a real implementation, you would save the charge to the server
    // and then add it to the selected charges
    console.log('Charge submitted:', charge);
    
    // For now, just close the dialog
    setIsChargeDialogOpen(false);
    setEditingCharge(null);
    
    toast({
      title: "Charge created",
      description: "The charge has been created successfully"
    });
    
    // In a real implementation, you would add the new charge ID to the selected charges
    // setSelectedCharges([...selectedCharges, newChargeId]);
  };

  const formatAmount = (amount: string) => {
    const numAmount = parseFloat(amount);
    if (isNaN(numAmount)) return amount;
    return (numAmount / 100).toFixed(2);
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Select Charges for {product?.name}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm text-gray-500 mb-4">
              Select which charges to add as variants for this product.
            </p>
            
            {product?.charges?.length === 0 ? (
              <div className="text-center p-6 border border-dashed rounded-md">
                <p className="text-gray-500">No charges available for this product.</p>
                <p className="text-sm text-gray-400 mt-1">Add charges to the product first.</p>
              </div>
            ) : (
              <div className="space-y-4">
                {product?.charges?.map(charge => (
                  <div key={charge.id} className="flex items-start space-x-2 p-3 border rounded-md">
                    <Checkbox
                      id={`charge-${charge.id}`}
                      checked={selectedCharges.includes(charge.id)}
                      onCheckedChange={() => handleToggleCharge(charge.id)}
                      className="mt-1"
                    />
                    <div className="flex-1">
                      <Label htmlFor={`charge-${charge.id}`} className="font-medium">
                        {charge.name}
                      </Label>
                      <div className="mt-1 text-sm text-gray-500">
                        <div className="flex items-center gap-2">
                          <span>Currency: {charge.currency}</span>
                          <span>•</span>
                          <span>Amount: {formatAmount(charge.amount)}</span>
                          <span>•</span>
                          <span>Type: {charge.type}</span>
                        </div>
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleEditCharge(charge)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
                
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleCreateCharge}
                  className="mt-2"
                >
                  <Plus className="h-4 w-4 mr-1" />
                  Create New Charge
                </Button>
              </div>
            )}
            
            {/* Debug Information */}
            <div className="mt-6 border-t border-gray-200 pt-4">
              <details className="text-sm">
                <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
                <div className="mt-2 space-y-4">
                  <div>
                    <h3 className="font-medium mb-1">Selected Charges:</h3>
                    <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                      {JSON.stringify(selectedCharges, null, 2)}
                    </pre>
                  </div>
                  <div>
                    <h3 className="font-medium mb-1">Product:</h3>
                    <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                      {JSON.stringify(product, null, 2)}
                    </pre>
                  </div>
                  {variantCategory && (
                    <div>
                      <h3 className="font-medium mb-1">Variant Category:</h3>
                      <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                        {JSON.stringify(variantCategory, null, 2)}
                      </pre>
                    </div>
                  )}
                </div>
              </details>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} disabled={selectedCharges.length === 0}>
              Add Selected Charges
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      
      <ChargeDialog 
        open={isChargeDialogOpen}
        onOpenChange={setIsChargeDialogOpen}
        onChargeCreated={handleChargeDialogClose}
        productId={product?.id}
        initialCharge={editingCharge as any}
      />
    </>
  );
} 