import { useState } from 'react';
import useSWR from 'swr';
import { getFetcher } from '~/lib/apiClient';
import { formatDateTime } from '../../utils/dateTime';
import { H1 } from "~/components/ui/typography";
import { Button } from "~/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import OrganizationLayout from "~/layouts/OrganizationLayout";

interface UsageEvent {
  id: number;
  organization_id: number;
  customer_id: number;
  agent_id: number | null;
  client_id: number;
  event_name: string;
  unique_id: string;
  properties: {
    quantity: number;
  };
  metadata: Record<string, any> | null;
  created_at: string;
  updated_at: string | null;
  customer?: {
    id: number;
    organization_id: number;
    connection_id: number | null;
    reference_id: string;
    email?: string;
  };
}

interface UsageEventsResponse {
  data: UsageEvent[];
  total: number;
  page: number;
  per_page: number;
}

export default function UsageEventsListPage() {
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const { data, error } = useSWR<UsageEventsResponse>(
    `/usage/events?page=${page}&per_page=${pageSize}`,
    getFetcher
  );

  return (
    <OrganizationLayout>
      <div className="space-y-6">
        <div>
          <H1>Usage Events</H1>
          <p className="text-muted-foreground mt-2">
            Track and monitor usage events across your organization
          </p>
        </div>

        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Customer Info</TableHead>
                <TableHead>Event Type</TableHead>
                <TableHead>Properties</TableHead>
                <TableHead>Timestamp</TableHead>
                <TableHead>Metadata</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {data?.data.map((event) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <div>
                      <a 
                        href={`/customers/${event.customer?.id}`}
                        className="font-medium text-primary hover:underline"
                      >
                        Customer {event.customer?.email || event.customer?.id}
                      </a>
                    </div>
                    <div className="text-sm text-muted-foreground">
                      ID: {event.unique_id}
                    </div>
                  </TableCell>
                  <TableCell>{event.event_name}</TableCell>
                  <TableCell>
                    <pre className="text-sm bg-muted p-2 rounded-md overflow-x-auto">
                      {JSON.stringify(event.properties, null, 2)}
                    </pre>
                  </TableCell>
                  <TableCell>{formatDateTime(event.created_at)}</TableCell>
                  <TableCell>
                    {event.metadata && (
                      <pre className="text-sm bg-muted p-2 rounded-md overflow-x-auto">
                        {JSON.stringify(event.metadata, null, 2)}
                      </pre>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="flex items-center justify-end gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPage((prev) => Math.max(1, prev - 1))}
            disabled={page === 1}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPage((prev) => prev + 1)}
            disabled={!data || page * pageSize >= data.total}
          >
            Next
          </Button>
        </div>
      </div>
    </OrganizationLayout>
  );
}
