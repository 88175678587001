import { Card, Text } from "@radix-ui/themes";
import { Search, Check, ChevronsUpDown, Edit, Trash2, PlusCircle, Settings, Plus, ExternalLink } from "lucide-react";
import { Offer, OfferItem, ItemVariant, VariantCategory } from "../types";
import { Product, Plan, Charge } from "~/types/catalog";
import useSWR from "swr";
import { getFetcher } from "~/lib/apiClient";
import VariantCategorySelector from "./VariantCategorySelector";
import { useOffer } from "../OfferDetailPage";
import * as React from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";
import apiClient from "~/lib/apiClient";
import { useToast } from "~/components/ui/use-toast";
import { useState, useEffect } from "react";
import ProductChargeSelector from "./ProductChargeSelector";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "~/components/ui/table";
import VariantDialog from "./VariantDialog";
import CategorySelector from "./CategorySelector";
import CategoryManager from "./CategoryManager";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

type Props = {
  offer: Offer;
  products: Product[] | { data: Product[] };
  plans: Plan[] | { data: Plan[] };
  handleAddItem: (itemId: string, type: 'product' | 'plan', role: 'core' | 'upsell') => void;
  handleRemoveItem: (itemId: string) => void;
  errors: Record<string, [string]>;
  pendingRemovals?: string[];
  onUndoRemove?: (itemId: string) => void;
};

// Define an extended OfferItem type that includes the variants property
interface ExtendedOfferItem extends Omit<OfferItem, 'charge_id'> {
  variants?: ItemVariant[];
  default_charge_id?: string | number;
  charge_id?: string | number;
  type?: 'product' | 'plan';
}

// Define component-specific types (not model types)
interface EditingVariant {
  itemId: string;
  variantIndex: number;
}

function ItemCombobox({ options, onSelect }: { 
  options: { value: string; label: string; description?: string; disabled?: boolean }[];
  onSelect: (value: string) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  
  console.log(options);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between"
        >
          Search...
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>No items found.</CommandEmpty>
            <CommandGroup>
              {options.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                    if (currentValue !== value) {
                      onSelect(currentValue);
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <div>
                    <div>{item.label}</div>
                    {item.description && (
                      <div className="text-sm text-muted-foreground">
                        {item.description}
                      </div>
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

// Helper function to format price display
const formatPrice = (amount: string | number | null): string => {
  if (amount === null || amount === undefined) return '0';
  
  // Handle string amounts
  if (typeof amount === 'string') {
    // If it's a currency string (contains $ or other symbols)
    if (amount.includes('$') || /[^\d.]/.test(amount)) {
      // Extract just the numeric part
      const numericValue = amount.replace(/[^\d.]/g, '');
      return numericValue || '0';
    }
    
    // If it's a numeric string, convert to number and divide by 100 if needed
    const numValue = parseFloat(amount);
    return isNaN(numValue) ? '0' : (numValue / 100).toString();
  }
  
  // Handle numeric amounts (convert from cents to dollars)
  return isNaN(amount) ? '0' : (amount / 100).toString();
};

// Helper function to get the actual products array
const getProductsArray = (products?: Product[] | { data: Product[] }): Product[] => {
  if (!products) return [];
  return Array.isArray(products) ? products : products.data;
};

// Helper function to find a charge by ID
const findChargeById = (chargeId: string | number, productsArray: Product[]): any => {
  if (!chargeId) return null;
  
  for (const product of productsArray) {
    if (!product.charges) continue;
    
    const foundCharge = product.charges.find(c => {
      // Try to parse both as integers for comparison
      const chargeIdInt = parseInt(String(chargeId), 10);
      const cIdInt = parseInt(String(c.id), 10);
      
      // If both are valid integers, compare them
      if (!isNaN(chargeIdInt) && !isNaN(cIdInt)) {
        return chargeIdInt === cIdInt;
      }
      
      // Otherwise, compare as strings
      return String(c.id) === String(chargeId);
    });
    
    if (foundCharge) return foundCharge;
  }
  
  return null;
};

// Render charge price based on charge type
const renderChargePrice = (charge: any, products?: Product[] | { data: Product[] }): React.ReactNode => {
  // For debugging
  
  if (!charge) return '0';
  
  // If this is just a charge reference (like in an OfferItem), find the actual charge
  if (products && charge.charge_id && !charge.type) {
    // Normalize products array
    const productsArray = getProductsArray(products);
    
    // Find the product that contains this charge
    let actualCharge = null;
    
    // Search through all products to find the matching charge
    for (const product of productsArray) {
      if (!product.charges) continue;
      
      // Find the charge by ID - convert both to integers for comparison
      const foundCharge = product.charges.find(c => {
        // Try to parse both as integers for comparison
        const chargeIdInt = parseInt(String(charge.charge_id), 10);
        const cIdInt = parseInt(String(c.id), 10);
        
        // If both are valid integers, compare them
        if (!isNaN(chargeIdInt) && !isNaN(cIdInt)) {
          return chargeIdInt === cIdInt;
        }
        
        // Otherwise, compare as strings
        return String(c.id) === String(charge.charge_id);
      });
      
      if (foundCharge) {
        actualCharge = foundCharge;
        break;
      }
    }
    
    // If we found the actual charge, use it
    if (actualCharge) {
      charge = actualCharge;
    }
  }
  
  // If we still don't have a proper charge with a type, return a fallback
  if (!charge.type) {
    // If we have an amount, format it
    if (charge.amount !== undefined && charge.amount !== null) {
      return formatPrice(charge.amount);
    }
    
    // Last resort fallback
    return '0';
  }
  
  switch (charge.type) {
    case 'standard':
    case 'one_time':
      return formatPrice(charge.amount);
      
    case 'volume':
    case 'graduated':
      if (!charge.properties || charge.properties.length === 0) {
        return formatPrice(charge.amount);
      }
      
      // For tiered pricing, show the first tier or a tiered indicator
      const firstTier = charge.properties[0];
      const hasTiers = charge.properties.length > 1;
      
      // Check if unit_amount exists and is valid
      if (firstTier && firstTier.unit_amount !== undefined && firstTier.unit_amount !== null) {
        return (
          <div>
            <div>{formatPrice(firstTier.unit_amount)} {hasTiers && '+'}</div>
            {hasTiers && (
              <div className="text-xs text-gray-500">
                {charge.properties.length} tier{charge.properties.length > 1 ? 's' : ''}
              </div>
            )}
          </div>
        );
      }
      
      // Fallback to charge amount if tier unit_amount is not available
      return formatPrice(charge.amount);
      
    case 'package':
      if (!charge.properties) {
        return formatPrice(charge.amount);
      }
      
      // Check if package properties exist and amount is valid
      if (charge.properties.amount !== undefined && charge.properties.amount !== null) {
        return (
          <div>
            <div>{formatPrice(charge.properties.amount)}</div>
            <div className="text-xs text-gray-500">
              per {charge.properties.package_size || 1} units
              {charge.properties.free_units ? ` (${charge.properties.free_units} free)` : ''}
            </div>
          </div>
        );
      }
      
      // Fallback to charge amount if package amount is not available
      return formatPrice(charge.amount);
      
    default:
      return formatPrice(charge.amount);
  }
};

export default function PricingTab({ offer, products, plans, handleAddItem, handleRemoveItem, errors, pendingRemovals, onUndoRemove }: Props) {
  const { updateOffer } = useOffer();
  const { toast } = useToast();
  const [editingVariant, setEditingVariant] = useState<EditingVariant | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [isProductChargeSelectorOpen, setIsProductChargeSelectorOpen] = useState(false);
  const [isVariantDialogOpen, setIsVariantDialogOpen] = useState(false);
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [selectedProductForCategories, setSelectedProductForCategories] = useState<string | null>(null);
  const [isCategoryManagerOpen, setIsCategoryManagerOpen] = useState(false);
  
  const { data: plansResponse, error: plansError, isLoading: isLoadingPlans } = useSWR<{ data: Plan[] }>(
    offer.checkout_intent === 'subscription' ? '/pricing/plans' : null,
    getFetcher
  );

  const availablePlans = plansResponse?.data || [];
  
  // Normalize products to always be an array
  const productsArray = React.useMemo(() => {
    return Array.isArray(products) ? products : products?.data || [];
  }, [products]);
  
  // Normalize plans to always be an array
  const plansArray = React.useMemo(() => {
    return Array.isArray(plans) ? plans : plans?.data || [];
  }, [plans]);

  // Fetch variant categories
  const { data: categoriesResponse, mutate: refreshCategories } = useSWR<{data: ItemVariant[] }>(
    '/store/variant_categories',
    getFetcher
  );
  
  const allCategories = categoriesResponse?.data || [];

  // Get the variant category for the offer
  const { data: categoryResponse } = useSWR<VariantCategory>(
    offer.variant_category_id ? `/store/variant_categories/${offer.variant_category_id}` : null,
    getFetcher
  );
  
  const offerCategory = categoryResponse;

  // Convert offerCategory to a proper VariantCategory for the dialog
  const variantCategoryForDialog = offerCategory ? {
    id: offerCategory.id || '',
    name: offerCategory.name || '',
    fields: offerCategory.fields || []
  } : null;

  // Log when the offer or offerCategory changes
  useEffect(() => {
    console.log('Offer changed:', offer);
    console.log('Offer variant_category_id:', offer.variant_category_id);
  }, [offer]);
  
  useEffect(() => {
    console.log('OfferCategory changed:', offerCategory);
  }, [offerCategory]);

  const getAvailableOptions = () => {
    if (offer.checkout_intent === 'subscription') {
      if (isLoadingPlans) return [{ value: '', label: 'Loading plans...', disabled: true }];
      if (plansError) return [{ value: '', label: 'Failed to load plans', disabled: true }];

      // Filter out plans that are already added
      const existingPlanIds = new Set(offer.items.map(item => item.id).filter(Boolean));
      return availablePlans
        .filter(plan => !existingPlanIds.has(plan.id))
        .map(plan => ({
          value: plan.id,
          label: plan.display_name,
          description: plan.description
        }));
    }

    // Filter out products that are already added
    const existingProductIds = new Set(offer.items.map(item => item.id).filter(Boolean));
    return productsArray
      .filter(product => !existingProductIds.has(product.id))
      .map(product => ({
        value: product.id,
        label: product.name,
        description: product.description
      }));
  };

  const handleUpdateVariants = (itemId: string, variants: any[]) => {
    // Find the item to update
    const itemIndex = offer.items.findIndex(item => item.id === itemId);
    if (itemIndex === -1) return;

    const updatedItems = [...offer.items];
    const item = { ...updatedItems[itemIndex] };

    // Ensure at least one variant is marked as default
    if (variants.length > 0 && !variants.some(v => v.is_default)) {
      variants[0].is_default = true;
    }

    // Update the item with the new variants and variant_properties
    (item as any).variants = variants;
    
    // If variants are provided, add variant_properties from the default variant
    if (variants && variants.length > 0) {
      const defaultVariant = variants.find(v => v.is_default) || variants[0];
      // Use the default variant's properties for backward compatibility
      item.variant_properties = defaultVariant.variant_properties;
      // Update the default charge ID
      (item as any).default_charge_id = defaultVariant.charge_id;
    } else {
      // Remove variant_properties if no variants
      item.variant_properties = null;
      delete (item as any).default_charge_id;
    }

    updatedItems[itemIndex] = item;
    updateOffer({ items: updatedItems });
  };

  const handleCreateCharge = async (itemId: string, currency: string, amount: number) => {
    try {
      // Find the product for this item
      const itemIndex = offer.items.findIndex(item => item.id === itemId);
      if (itemIndex === -1) return { id: '', name: '' };

      const item = offer.items[itemIndex];
      if ((item as any).type !== 'product') {
        toast({
          title: "Error",
          description: "Can only create charges for products",
          variant: "destructive"
        });
        return { id: '', name: '' };
      }

      const product = productsArray.find(p => p.id === item.id);

      if (!product) {
        toast({
          title: "Error",
          description: "Product not found",
          variant: "destructive"
        });
        return { id: '', name: '' };
      }

      // Create a new charge for the product
      const response = await apiClient.post(`/catalog/products/${product.id}/charges`, {
        name: `${product.name} - ${currency}`,
        amount: amount.toString(),
        currency,
        type: 'standard',
        mode: 'in_advance'
      });

      toast({
        title: "Success",
        description: `Created new charge for ${currency}`,
      });

      return { 
        id: response.data.id.toString(), 
        name: response.data.name 
      };
    } catch (error) {
      console.error('Error creating charge:', error);
      toast({
        title: "Error",
        description: "Failed to create charge",
        variant: "destructive"
      });
      return { id: '', name: '' };
    }
  };

  const handleEditVariantClick = (itemId: string, variantIndex: number = 0) => {
    // Find the item to edit
    const item = offer.items.find(item => item.id === itemId);
    if (!item) return;
    
    // Set the editing variant
    setEditingVariant({ itemId, variantIndex });
    
    // Open the dialog
    setIsVariantDialogOpen(true);
  };

  const handleCloseVariantEditor = () => {
    setEditingVariant(null);
    setIsVariantDialogOpen(false);
  };

  const handleSaveVariant = (updatedVariant: any) => {
    if (!editingVariant) return;

    // Find the item to update
    const itemIndex = offer.items.findIndex(item => item.id === editingVariant.itemId);
    if (itemIndex === -1) return;

    const updatedItems = [...offer.items];
    const item = { ...updatedItems[itemIndex] };
    
    // Update the item properties directly
    item.name = updatedVariant.name;
    item.variant_properties = updatedVariant.variant_properties;
    item.is_default = updatedVariant.is_default;
    
    // Only update charge_id for product variants
    if (item.type === 'product' || item.purchasable_type === 'product') {
      // Update charge_id if it changed
      if (updatedVariant.charge_id && item.charge_id !== updatedVariant.charge_id) {
        item.charge_id = updatedVariant.charge_id;
        
        // Find the charge in the product's charges
        const product = productsArray.find(p => p.id === item.purchasable_id);
        if (product) {
          const charge = findChargeById(updatedVariant.charge_id, [product]);
          if (charge) {
            item.charge = charge as unknown as Charge;
          }
        }
      }
    }
    
    // For backward compatibility, also update the variants array if it exists
    if ((item as any).variants) {
      const variants = [...((item as any).variants || [])];
      variants[editingVariant.variantIndex] = {
        ...variants[editingVariant.variantIndex],
        ...updatedVariant
      };
      
      // Ensure at least one variant is marked as default
      if (variants.length > 0 && !variants.some(v => v.is_default)) {
        variants[0].is_default = true;
      }
      
      (item as any).variants = variants;
    }
    
    // If this is the default variant, make sure other items for the same product are not default
    if (item.is_default) {
      const productId = item.purchasable_id;
      updatedItems.forEach((otherItem, idx) => {
        if (idx !== itemIndex && otherItem.purchasable_id === productId) {
          updatedItems[idx] = {
            ...otherItem,
            is_default: false
          };
        }
      });
    }

    updatedItems[itemIndex] = item;

    // Update the offer
    updateOffer({
      items: updatedItems
    });

    // Close the editor
    handleCloseVariantEditor();

    toast({
      title: "Success",
      description: "Variant updated successfully"
    });
  };

  const handleProductSelect = (productId: string) => {
    // For subscription plans, add directly
    if (offer.checkout_intent === 'subscription') {
      handleAddItem(productId, 'plan', 'core');
      return;
    }

    // For products, open the charge selector
    const product = productsArray.find(p => p.id === productId);
    if (product) {
      setSelectedProductId(productId);
      setIsProductChargeSelectorOpen(true);
    }
  };

  const handleProductChargesSelected = (product: Product, selectedCharges: (string | number)[]) => {
    // Create offer items for each selected charge (each OfferItem is a Variant)
    const newItems = selectedCharges.map(chargeId => {
      // Find the charge by ID, handling both string and number types
      const charge = findChargeById(chargeId, [product]);
      
      if (!charge) return null;

      // Initialize variant properties with the offer category
      const variantProperties: Record<string, string | number | boolean> = {};
      
      // If there's a variant category, initialize properties using field IDs as keys
      if (offerCategory) {
        offerCategory.fields?.forEach?.(field => {
          if (!field) return; // Skip if field is null or undefined
          // Set default values based on field type
          switch (field.type) {
            case 'string':
              variantProperties[field.id] = '';
              break;
            case 'number':
              variantProperties[field.id] = 0;
              break;
            case 'boolean':
              variantProperties[field.id] = false;
              break;
            case 'file':
              variantProperties[field.id] = '';
              break;
            default:
              variantProperties[field.id] = '';
          }
        });
      }

      // Create a new offer item (variant) for this charge
      return {
        id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
        name: `${product.name} - ${charge.name}`, // Include charge name in the item name
        object: 'offer_item',
        type: 'product' as const,
        role: 'core' as const,
        purchasable_id: product.id,
        purchasable_type: 'product' as const,
        purchasable_name: product.name,
        charge_id: charge.id, // Use the charge ID directly
        charge: charge as unknown as Charge, // Include the full charge object with type assertion
        variant_properties: variantProperties, // Apply variant properties directly to the OfferItem
        is_default: false, // First one will be set to default below
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
    }).filter(Boolean) as OfferItem[];

    // Set the first item as default if there are any
    if (newItems.length > 0) {
      newItems[0].is_default = true;
    }

    // Add the new items to the offer
    updateOffer({
      items: [...offer.items, ...newItems]
    });

    // Close the selector
    setIsProductChargeSelectorOpen(false);
    setSelectedProductId(null);

    toast({
      title: "Success",
      description: `Added ${newItems.length} variants for ${product.name}`
    });
  };

  const handleAddCategoryToAllVariants = (productId: string) => {
    setSelectedProductForCategories(productId);
    setIsCategoryDialogOpen(true);
  };

  const handleCategoryUpdated = () => {
    // Refresh the categories
    refreshCategories();
  };

  // Add a function to handle setting the variant category for the offer
  const handleSetVariantCategory = (categoryId: string) => {
    console.log('Setting variant category ID:', categoryId || 'null (clearing category)');
    
    // Update the offer with the selected category
    // If categoryId is empty, set variant_category_id to null
    updateOffer({
      variant_category_id: categoryId || null
    });
    
    // Log the updated offer
    setTimeout(() => {
      console.log('Updated offer after setting category:', offer);
    }, 100);
    
    toast({
      title: "Category Set",
      description: categoryId ? `Updated the variant category for this offer` : `Removed the variant category from this offer`,
    });
  };

  // Add a button to set the variant category for the offer
  const renderCategorySelector = () => {
    return (
      <div className="flex items-center gap-2">
        <div className="text-sm font-medium">Variant Category:</div>
        <div className="text-sm">
          {offerCategory 
            ? offerCategory.name
            : offer.variant_category_id 
              ? "Loading..."
              : "None"}
        </div>
        <Button 
          variant="outline" 
          size="sm"
          className="ml-2 h-7 px-2 py-1 text-xs"
          onClick={() => setIsCategoryDialogOpen(true)}
        >
          {offerCategory ? "Change" : "Set"}
        </Button>
        {!offerCategory && !offer.variant_category_id && (
          <span className="text-xs text-red-500 ml-1">
            Required
          </span>
        )}
      </div>
    );
  };

  const renderItemList = () => {
    if (offer.checkout_intent === 'subscription' && isLoadingPlans) {
      return (
        <div className="text-center py-12">
          <Text size="2" color="gray">Loading plans...</Text>
        </div>
      );
    }

    if (offer.checkout_intent === 'subscription' && plansError) {
      return (
        <div className="text-center py-12">
          <Text size="2" color="red">Failed to load plans. Please try again.</Text>
        </div>
      );
    }

    if (offer.items.length === 0) {
      return (
        <div className="text-center py-12 border-2 border-dashed rounded">
          <Search className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <Text as="div" size="5" weight="medium">No items added</Text>
          <Text as="p" size="2" color="gray" className="mt-1">
            Search for {offer.checkout_intent === 'subscription' ? 'plans' : 'products'} to add to this offer
          </Text>
        </div>
      );
    }

    // Group items by purchasable ID (product or plan)
    const itemsByPurchasable = offer.items.reduce((acc, item) => {
      // Skip items that are not of the correct type for the offer checkout_intent
      const itemType = item.type || item.purchasable_type;
      if ((offer.checkout_intent === 'order' && itemType !== 'product') || 
          (offer.checkout_intent === 'subscription' && itemType !== 'plan')) {
        return acc;
      }
      
      if (!acc[item.purchasable_id]) {
        acc[item.purchasable_id] = [];
      }
      acc[item.purchasable_id].push(item);
      return acc;
    }, {} as Record<string, OfferItem[]>);

    return Object.entries(itemsByPurchasable).map(([purchasableId, items]) => {
      // For subscription offers, handle plans and packages
      if (offer.checkout_intent === 'subscription') {
        if (isLoadingPlans) return null;  
        if (plansError) return null;
        
        // Use availablePlans first (from SWR), then fallback to props plans
        const plan = availablePlans.find(p => p.id === purchasableId) || 
                    plansArray.find(p => p.id === purchasableId);
        
        // Check if this is a package or a plan
        const firstItem = items[0];
        const isPackage = firstItem.type === 'package' || firstItem.purchasable_type === 'package';
        
        // For packages, we'll show a different UI
        if (isPackage) {
          // We don't have package data in the props, so we'll just use the first item's name
          const packageName = firstItem.purchasable_name || 'Package';
          
          return (
            <Card key={purchasableId} className="mb-4">
              <div className="border border-gray-200 border-dashed rounded-lg divide-y divide-gray-200">
                <div className="flex justify-between items-start p-4">
                  <div>
                    <div className="flex items-center gap-2">
                      <Text as="div" size="3" weight="medium">{packageName}</Text>
                      <Text as="div" size="2" color="gray" className="text-xs">
                        Package with {items.length} variant{items.length !== 1 ? 's' : ''}
                      </Text>
                    </div>
                    <div className="mt-2 space-y-1">
                      <Text as="div" size="2" color="gray" className="flex items-center gap-1">
                        <span>Type: Package</span>
                      </Text>
                      {firstItem.plans && firstItem.plans.length > 0 && (
                        <Text as="div" size="2" color="gray" className="flex items-center gap-1">
                          <span>Contains {firstItem.plans.length} plan{firstItem.plans.length !== 1 ? 's' : ''}</span>
                        </Text>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="h-8 px-2 py-1"
                      onClick={() => handleAddVariant(purchasableId)}
                    >
                      <Plus className="h-4 w-4 mr-1" /> Variant
                    </Button>
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="text-destructive hover:bg-destructive/10 hover:text-destructive"
                      onClick={() => {
                        // Remove all items for this package
                        if (window.confirm(`Are you sure you want to remove all variants for ${packageName}?`)) {
                          items.forEach(item => handleRemoveItem(item.id));
                        }
                      }}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                <div>
                  {items.length === 0 ? (
                    <div className="text-center p-4 border border-dashed rounded">
                      <Text as="p" size="2" color="gray">
                        No variants added yet. Add variants to create different versions of this package.
                      </Text>
                    </div>
                  ) : (
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead className="pl-4">Variant</TableHead>
                          <TableHead>Package Details</TableHead>
                          <TableHead>Properties</TableHead>
                          <TableHead className="text-right w-[100px] pr-4">Actions</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {items.map((item, index) => {
                          // Check if the item is marked as default
                          const isDefault = item.is_default === true;
                          
                          return (
                            <TableRow key={index}>
                              <TableCell className="pl-4">
                                <div className="font-medium">
                                  {item.name || `Variant ${index + 1}`}
                                  {isDefault && (
                                    <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Default</span>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="space-y-1">
                                  {item.plans && item.plans.length > 0 ? (
                                    <div className="text-sm">
                                      Contains {item.plans.length} plan{item.plans.length !== 1 ? 's' : ''}
                                    </div>
                                  ) : (
                                    <div className="text-sm text-gray-500">No plans specified</div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                {Object.keys(item.variant_properties || {}).length > 0 ? (
                                  <div className="text-xs">
                                    {Object.entries(item.variant_properties || {}).map(([key, value]) => {
                                      // Try to find the field name if we have a category
                                      let fieldName = key;
                                      if (offerCategory) {
                                        const field = offerCategory.fields?.find(f => f?.id === key);
                                        if (field) {
                                          fieldName = field.name;
                                        }
                                      }
                                      
                                      return (
                                        <div key={key} className="mb-1">
                                          <span className="font-medium">{fieldName}:</span> {value?.toString?.() || ''}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <span className="text-gray-500 text-xs">No properties</span>
                                )}
                              </TableCell>
                              <TableCell className="text-right pr-2">
                                <div className="flex justify-end gap-1">
                                  {/* Add checkout link for variants that exist in the backend */}
                                  {item.created_at && (
                                    <TooltipProvider>
                                      <Tooltip>
                                        <TooltipTrigger asChild>
                                          <Button 
                                            variant="ghost" 
                                            size="sm"
                                            className="h-8 w-8 p-0 text-blue-500 hover:bg-blue-50 hover:text-blue-600"
                                            onClick={() => {
                                              const checkoutUrl = `${offer.urls.checkout}?variant=${item.id}`;
                                              window.open(checkoutUrl, '_blank');
                                            }}
                                          >
                                            <ExternalLink className="h-4 w-4" />
                                          </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                          <p>{`${offer.urls.checkout}?variant=${item.id}`}</p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </TooltipProvider>
                                  )}
                                  <Button 
                                    variant="ghost" 
                                    size="sm"
                                    className="h-8 w-8 p-0"
                                    onClick={() => handleEditVariantClick(item.id, 0)}
                                  >
                                    <Edit className="h-4 w-4" />
                                  </Button>
                                  {items.length > 1 && (
                                    <Button 
                                      variant="ghost" 
                                      size="sm"
                                      className="h-8 w-8 p-0 text-destructive hover:bg-destructive/10 hover:text-destructive"
                                      onClick={() => handleRemoveItem(item.id)}
                                    >
                                      <Trash2 className="h-4 w-4" />
                                    </Button>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </div>

                {firstItem.plans && firstItem.plans.length > 0 && (
                  <div className="p-4">
                    <Text as="div" size="2" weight="medium" className="mb-2">Associated Plans</Text>
                    <div className="space-y-2">
                      {firstItem.plans.map(planInfo => {
                        const associatedPlan = availablePlans.find(p => p.id === planInfo.id) || 
                                              plansArray.find(p => p.id === planInfo.id);
                        
                        if (!associatedPlan) {
                          return (
                            <div key={planInfo.id} className="p-2 border rounded">
                              <div className="font-medium">{planInfo.name || 'Unknown Plan'}</div>
                              <div className="text-xs text-gray-500">Plan details not available</div>
                            </div>
                          );
                        }
                        
                        return (
                          <div key={associatedPlan.id} className="p-2 border rounded">
                            <div className="font-medium">{associatedPlan.display_name}</div>
                            <div className="text-xs text-gray-500">
                              {associatedPlan.currency_flag} {associatedPlan.currency_code} - 
                              {associatedPlan.charges.length} charge{associatedPlan.charges.length !== 1 ? 's' : ''}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Card>
          );
        }
        
        // For regular plans, use the existing UI
        if (!plan) return null;

        return (
          <Card key={purchasableId} className="mb-4">
            <div className="border border-gray-200 border-dashed rounded-lg divide-y divide-gray-200">
              <div className="flex justify-between items-start p-4">
                <div>
                  <div className="flex items-center gap-2">
                    <Text as="div" size="3" weight="medium">{plan.display_name}</Text>
                    <Text as="div" size="2" color="gray" className="text-xs">
                      {items.length} variant{items.length !== 1 ? 's' : ''}
                    </Text>
                  </div>
                  {plan.description && (
                    <Text as="div" size="2" color="gray" className="mt-1 text-sm">{plan.description}</Text>
                  )}
                  <div className="mt-2 space-y-1">
                    <Text as="div" size="2" color="gray" className="flex items-center gap-1">
                      <span>{plan.currency_flag}</span>
                      <span>Billed in {plan.currency_code}</span>
                    </Text>
                    <Text as="div" size="2" color="gray" className="flex items-center gap-1">
                      <span>Type:</span>
                      <span className="capitalize">{plan.type}</span>
                    </Text>
                  </div>
                </div>
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="h-8 px-2 py-1"
                    onClick={() => handleAddVariant(purchasableId)}
                  >
                    <Plus className="h-4 w-4 mr-1" /> Variant
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="text-destructive hover:bg-destructive/10 hover:text-destructive"
                    onClick={() => {
                      // Remove all items for this plan
                      if (window.confirm(`Are you sure you want to remove all variants for ${plan.display_name}?`)) {
                        items.forEach(item => handleRemoveItem(item.id));
                      }
                    }}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              <div>
                {items.length === 0 ? (
                  <div className="text-center p-4 border border-dashed rounded">
                    <Text as="p" size="2" color="gray">
                      No variants added yet. Add variants to create different versions of this plan.
                    </Text>
                  </div>
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="pl-4">Variant</TableHead>
                        <TableHead>Plan Details</TableHead>
                        <TableHead>Properties</TableHead>
                        <TableHead className="text-right w-[100px] pr-4">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {items.map((item, index) => {
                        // Check if the item is marked as default
                        const isDefault = item.is_default === true;
                        
                        return (
                          <TableRow key={index}>
                            <TableCell className="pl-4">
                              <div className="font-medium">
                                {item.name || `Variant ${index + 1}`}
                                {isDefault && (
                                  <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Default</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="space-y-1">
                                <div className="text-sm">
                                  {plan.charges.length} charge{plan.charges.length !== 1 ? 's' : ''}
                                </div>
                                {plan.charges.length > 0 && (
                                  <div className="text-xs text-gray-500">
                                    First charge: {plan.charges[0].name} - {renderChargePrice(plan.charges[0], products)}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              {Object.keys(item.variant_properties || {}).length > 0 ? (
                                <div className="text-xs">
                                  {Object.entries(item.variant_properties || {}).map(([key, value]) => {
                                    // Try to find the field name if we have a category
                                    let fieldName = key;
                                    if (offerCategory) {
                                      const field = offerCategory.fields?.find(f => f?.id === key);
                                      if (field) {
                                        fieldName = field.name;
                                      }
                                    }
                                    
                                    return (
                                      <div key={key} className="mb-1">
                                        <span className="font-medium">{fieldName}:</span> {value?.toString?.() || ''}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <span className="text-gray-500 text-xs">No properties</span>
                              )}
                            </TableCell>
                            <TableCell className="text-right pr-2">
                              <div className="flex justify-end gap-1">
                                {/* Add checkout link for variants that exist in the backend */}
                                {item.created_at && (
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Button 
                                          variant="ghost" 
                                          size="sm"
                                          className="h-8 w-8 p-0 text-blue-500 hover:bg-blue-50 hover:text-blue-600"
                                          onClick={() => {
                                            const checkoutUrl = `${offer.urls.checkout}?variant=${item.id}`;
                                            window.open(checkoutUrl, '_blank');
                                          }}
                                        >
                                          <ExternalLink className="h-4 w-4" />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>{`${offer.urls.checkout}?variant=${item.id}`}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                )}
                                <Button 
                                  variant="ghost" 
                                  size="sm"
                                  className="h-8 w-8 p-0"
                                  onClick={() => handleEditVariantClick(item.id, 0)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                                {items.length > 1 && (
                                  <Button 
                                    variant="ghost" 
                                    size="sm"
                                    className="h-8 w-8 p-0 text-destructive hover:bg-destructive/10 hover:text-destructive"
                                    onClick={() => handleRemoveItem(item.id)}
                                  >
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </div>

              {plan.charges.length > 0 && (
                <div className="p-4">
                  <Text as="div" size="2" weight="medium" className="mb-2">Plan Charges</Text>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Name</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead>Mode</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {plan.charges.map(charge => (
                        <TableRow key={charge.id}>
                          <TableCell className="font-medium">{charge.name}</TableCell>
                          <TableCell>{renderChargePrice(charge, products)}</TableCell>
                          <TableCell className="capitalize">{charge.mode.replace('_', ' ')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          </Card>
        );
      }

      // For one-time offers, handle products
      const product = productsArray.find(p => p.id === purchasableId);
      if (!product) return null;

      // Count how many charges this product has and how many are used in the offer
      const totalCharges = product.charges.length;
      const usedChargeIds = new Set(items.map(item => (item as any).charge_id).filter(Boolean));
      const unusedCharges = product.charges.filter(charge => !usedChargeIds.has(charge.id.toString()));

      return (
        <Card key={purchasableId} className="mb-4">
          <div className="border border-gray-200 border-dashed rounded-lg divide-y divide-gray-200">
            <div className="flex justify-between items-start p-4">
              <div>
                <div className="flex items-center gap-2">
                  <Text as="div" size="3" weight="medium">{product.name}</Text>
                  <Text as="div" size="2" color="gray" className="text-xs">
                    {usedChargeIds.size} of {totalCharges} charges used
                  </Text>
                </div>
                {product.description && (
                  <Text as="div" size="2" color="gray" className="mt-1 text-sm">{product.description}</Text>
                )}
              </div>
              <div className="flex gap-2">
                <Button 
                  variant="outline" 
                  size="sm"
                  className="h-8 px-2 py-1"
                  onClick={() => handleAddVariant(purchasableId)}
                >
                  <Plus className="h-4 w-4 mr-1" /> Variant
                </Button>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="text-destructive hover:bg-destructive/10 hover:text-destructive"
                  onClick={() => {
                    // Remove all items for this product
                    if (window.confirm(`Are you sure you want to remove all variants for ${product.name}?`)) {
                      items.forEach(item => handleRemoveItem(item.id));
                    }
                  }}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <div className="">
              
                {items.length === 0 ? (
                  <div className="text-center p-4 border border-dashed rounded">
                    <Text as="p" size="2" color="gray">
                      No variants added yet. Add variants to create different versions of this product.
                    </Text>
                  </div>
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="pl-4">Variant</TableHead>
                        <TableHead>Charge</TableHead>
                        <TableHead>Price</TableHead>
                        <TableHead>Properties</TableHead>
                        <TableHead className="text-right w-[100px] pr-4">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {items.map((item, index) => {
                        // Get the charge from either the charge object or by finding it using charge_id
                        const chargeForItem = item.charge || 
                          (item.charge_id ? findChargeById(item.charge_id, getProductsArray(products)) : null);
                        
                        // If we can't find the charge, skip this item
                        if (!chargeForItem) return null;
                        
                        // Check if the item is marked as default
                        const isDefault = item.is_default === true;
                        
                        return (
                          <TableRow key={index}>
                            <TableCell className="pl-4">
                              <div className="font-medium">
                                {item.name || `Variant ${index + 1}`}
                                {isDefault && (
                                  <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded">Default</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div>{chargeForItem.name}</div>
                              <div className="text-xs text-gray-500">{chargeForItem.currency}</div>
                            </TableCell>
                            {/* Price */}
                            <TableCell>
                              {renderChargePrice(chargeForItem, products)}
                            </TableCell>
                            <TableCell>
                              {Object.keys(item.variant_properties || {}).length > 0 ? (
                                <div className="text-xs">
                                  {Object.entries(item.variant_properties || {}).map(([key, value]) => {
                                    // Try to find the field name if we have a category
                                    let fieldName = key;
                                    if (offerCategory) {
                                      const field = offerCategory.fields.find(f => f.id === key);
                                      if (field) {
                                        fieldName = field.name;
                                      }
                                    }
                                    
                                    return (
                                      <div key={key} className="mb-1">
                                        <span className="font-medium">{fieldName}:</span> {value?.toString?.() || ''}
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <span className="text-gray-500 text-xs">No properties</span>
                              )}
                            </TableCell>
                            <TableCell className="text-right pr-2">
                              <div className="flex justify-end gap-1">
                                {/* Add checkout link for variants that exist in the backend */}
                                {item.created_at && (
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Button 
                                          variant="ghost" 
                                          size="sm"
                                          className="h-8 w-8 p-0 text-blue-500 hover:bg-blue-50 hover:text-blue-600"
                                          onClick={() => {
                                            const checkoutUrl = `${offer.urls.checkout}?variant=${item.id}`;
                                            window.open(checkoutUrl, '_blank');
                                          }}
                                        >
                                          <ExternalLink className="h-4 w-4" />
                                        </Button>
                                      </TooltipTrigger>
                                      <TooltipContent>
                                        <p>{`${offer.urls.checkout}?variant=${item.id}`}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                )}
                                <Button 
                                  variant="ghost" 
                                  size="sm"
                                  className="h-8 w-8 p-0"
                                  onClick={() => handleEditVariantClick(item.id, 0)}
                                >
                                  <Edit className="h-4 w-4" />
                                </Button>
                                {items.length > 1 && (
                                  <Button 
                                    variant="ghost" 
                                    size="sm"
                                    className="h-8 w-8 p-0 text-destructive hover:bg-destructive/10 hover:text-destructive"
                                    onClick={() => handleRemoveItem(item.id)}
                                  >
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </div>
          </div>
        </Card>
      );
    });
  };

  // Find the selected product for the charge selector
  const selectedProduct = selectedProductId 
    ? productsArray.find(p => p.id === selectedProductId) 
    : null;

  // Get the variant being edited, if any
  const currentEditingVariant = editingVariant 
    ? (() => {
        const item = offer.items.find(item => item.id === editingVariant.itemId);
        if (!item) return null;
        
        // Create a variant object from the item properties
        return {
          id: item.id,
          name: item.name,
          currency: item.charge?.currency || '',
          charge_id: item.charge_id || '',
          amount: item.charge ? parseFloat(formatPrice(item.charge.amount)) : 0,
          variant_properties: item.variant_properties || {},
          is_default: item.is_default || false,
          type: item.type || item.purchasable_type // Add the type to distinguish between product and plan variants
        };
      })()
    : null;

  // Get the charge name for the variant being edited
  const currentEditingChargeName = editingVariant
    ? (() => {
        const item = offer.items.find(item => item.id === editingVariant.itemId);
        if (!item) return '';
        
        // If the item has a charge object, use its name
        if (item.charge?.name) {
          return item.charge.name;
        }
        
        // Otherwise try to find the charge in the product's charges
        const product = productsArray.find(p => p.id === item.purchasable_id);
        if (!product) return '';
        
        const foundCharge = findChargeById(item.charge_id, productsArray);
        return foundCharge?.name || '';
      })()
    : '';

  // Add a function to create a new variant for a product, plan, or package
  const handleAddVariant = (purchasableId: string) => {
    // For subscription offers, handle plans and packages
    if (offer.checkout_intent === 'subscription') {
      // Find the first item with this purchasable ID to determine if it's a plan or package
      const existingItem = offer.items.find(item => item.purchasable_id === purchasableId);
      const isPackage = existingItem && (existingItem.type === 'package' || existingItem.purchasable_type === 'package');
      
      if (isPackage) {
        // For packages, create a new package variant
        const packageName = existingItem?.purchasable_name || 'Package';
        
        // Initialize variant properties with the offer category
        const variantProperties: Record<string, string | number | boolean> = {};
        
        // If there's a variant category, initialize properties using field IDs as keys
        if (offerCategory) {
          offerCategory.fields?.forEach?.(field => {
            if (!field) return; // Skip if field is null or undefined
            // Set default values based on field type
            switch (field.type) {
              case 'string':
                variantProperties[field.id] = '';
                break;
              case 'number':
                variantProperties[field.id] = 0;
                break;
              case 'boolean':
                variantProperties[field.id] = false;
                break;
              case 'file':
                variantProperties[field.id] = '';
                break;
              default:
                variantProperties[field.id] = '';
            }
          });
        }
        
        // Create a new offer item (variant) for this package
        const newItem: OfferItem = {
          id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
          name: `${packageName} Variant`, // Default variant name
          object: 'offer_item',
          type: 'package' as const,
          role: 'core' as const,
          purchasable_id: purchasableId,
          purchasable_type: 'package' as const,
          purchasable_name: packageName,
          variant_properties: variantProperties, // Apply variant properties directly to the OfferItem
          is_default: false,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          // Copy plans from the existing item if available
          plans: existingItem?.plans ? [...existingItem.plans] : []
        };
        
        // Add the new item to the offer
        updateOffer({
          items: [...offer.items, newItem]
        });
        
        // Open the variant dialog to edit the new variant
        setTimeout(() => {
          handleEditVariantClick(newItem.id, 0);
        }, 100);
        
        toast({
          title: "Variant Added",
          description: `Added a new variant for ${packageName}`,
        });
        
        return;
      }
      
      // For plans, use the existing code
      // Find the plan
      const plan = availablePlans.find(p => p.id === purchasableId) || 
                  plansArray.find(p => p.id === purchasableId);
      if (!plan) return;
      
      // Initialize variant properties with the offer category
      const variantProperties: Record<string, string | number | boolean> = {};
      
      // If there's a variant category, initialize properties using field IDs as keys
      if (offerCategory) {
        offerCategory.fields?.forEach?.(field => {
          if (!field) return; // Skip if field is null or undefined
          // Set default values based on field type
          switch (field.type) {
            case 'string':
              variantProperties[field.id] = '';
              break;
            case 'number':
              variantProperties[field.id] = 0;
              break;
            case 'boolean':
              variantProperties[field.id] = false;
              break;
            case 'file':
              variantProperties[field.id] = '';
              break;
            default:
              variantProperties[field.id] = '';
          }
        });
      }
      
      // Create a new offer item (variant) for this plan
      const newItem: OfferItem = {
        id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
        name: `${plan.display_name} Variant`, // Default variant name
        object: 'offer_item',
        type: 'plan' as const,
        role: 'core' as const,
        purchasable_id: plan.id,
        purchasable_type: 'plan' as const,
        purchasable_name: plan.display_name,
        variant_properties: variantProperties, // Apply variant properties directly to the OfferItem
        is_default: false,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      
      // Add the new item to the offer
      updateOffer({
        items: [...offer.items, newItem]
      });
      
      // Open the variant dialog to edit the new variant
      setTimeout(() => {
        handleEditVariantClick(newItem.id, 0);
      }, 100);
      
      toast({
        title: "Variant Added",
        description: `Added a new variant for ${plan.display_name}`,
      });
      
      return;
    }
    
    // For one-time offers, handle products
    const product = productsArray.find(p => p.id === purchasableId);
    if (!product) return;
    
    // If the product has no charges, show an error
    if (!product.charges || product.charges.length === 0) {
      toast({
        title: "No Charges Available",
        description: "This product has no charges. Please add charges to the product first.",
        variant: "destructive"
      });
      return;
    }
    
    // Use the first charge for the new variant
    const charge = product.charges[0];
    
    // Initialize variant properties with the offer category
    const variantProperties: Record<string, string | number | boolean> = {};
    
    // If there's a variant category, initialize properties using field IDs as keys
    if (offerCategory) {
      offerCategory.fields?.forEach?.(field => {
        if (!field) return; // Skip if field is null or undefined
        // Set default values based on field type
        switch (field.type) {
          case 'string':
            variantProperties[field.id] = '';
            break;
          case 'number':
            variantProperties[field.id] = 0;
            break;
          case 'boolean':
            variantProperties[field.id] = false;
            break;
          case 'file':
            variantProperties[field.id] = '';
            break;
          default:
            variantProperties[field.id] = '';
        }
      });
    }
    
    // Create a new offer item (variant) for this charge
    const newItem: OfferItem = {
      id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`, // Temporary ID
      name: `${product.name} - ${charge.name}`, // Include charge name in the item name
      object: 'offer_item',
      type: 'product' as const,
      role: 'core' as const,
      purchasable_id: product.id,
      purchasable_type: 'product' as const,
      purchasable_name: product.name,
      charge_id: charge.id, // Use the charge ID directly
      charge: charge as unknown as Charge, // Include the full charge object with type assertion
      variant_properties: variantProperties, // Apply variant properties directly to the OfferItem
      is_default: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    
    // Add the new item to the offer
    updateOffer({
      items: [...offer.items, newItem]
    });
    
    // Open the variant dialog to edit the new variant
    setTimeout(() => {
      handleEditVariantClick(newItem.id, 0);
    }, 100);
    
    toast({
      title: "Variant Added",
      description: `Added a new variant for ${product.name}`,
    });
  };

  return (
    <div className="space-y-6">
      {/* Header with Search and Actions */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h2 className="text-lg font-semibold">Pricing</h2>
          {renderCategorySelector()}
        </div>
        <div className="flex gap-2">
          {/* <Button 
            variant="default"
            size="sm"
            onClick={() => {
              // Trigger the save function in the parent component
              const saveButton = document.querySelector('button[type="submit"]') as HTMLButtonElement;
              if (saveButton) {
                saveButton.click();
              } else {
                console.error('Save button not found');
              }
            }}
          >
            Save Changes
          </Button> */}
          <ItemCombobox 
            options={getAvailableOptions()} 
            onSelect={handleProductSelect}
          />
          <Button 
            variant="outline"
            size="sm"
            onClick={() => setIsCategoryManagerOpen(true)}
          >
            <Settings className="h-4 w-4 mr-1" />
            Manage Categories
          </Button>
        </div>
      </div>
      
      <div className="space-y-4">
        {renderItemList()}
      </div>

      {/* Debug Information */}
      <div className="mt-8 border-t border-gray-200 pt-4">
        <details className="text-sm">
          <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
          <div className="mt-2 space-y-4">
            <div>
              <h3 className="font-medium mb-1">Current Offer State:</h3>
              <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                {JSON.stringify(offer, null, 2)}
              </pre>
            </div>
            <div>
              <h3 className="font-medium mb-1">Variant Category:</h3>
              <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                {JSON.stringify(offerCategory, null, 2)}
              </pre>
            </div>
          </div>
        </details>
      </div>

      {/* Variant editor dialog */}
      {editingVariant && currentEditingVariant && (
        <VariantDialog
          open={isVariantDialogOpen}
          onOpenChange={setIsVariantDialogOpen}
          variant={currentEditingVariant}
          onSave={handleSaveVariant}
          chargeName={currentEditingChargeName}
          variantCategory={variantCategoryForDialog}
        />
      )}

      {/* Product charge selector dialog */}
      {selectedProduct && (
        <ProductChargeSelector
          open={isProductChargeSelectorOpen}
          onOpenChange={setIsProductChargeSelectorOpen}
          product={selectedProduct}
          onConfirm={handleProductChargesSelected}
          variantCategory={variantCategoryForDialog}
        />
      )}

      {/* Category selector dialog */}
      <CategorySelector
        open={isCategoryDialogOpen}
        onOpenChange={setIsCategoryDialogOpen}
        onCategorySelected={handleSetVariantCategory}
        currentCategoryId={offer.variant_category_id}
      />

      {/* Category manager dialog */}
      <CategoryManager
        open={isCategoryManagerOpen}
        onOpenChange={setIsCategoryManagerOpen}
        onCategoryUpdated={handleCategoryUpdated}
      />
    </div>
  );
} 