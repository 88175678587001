import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "~/components/ui/dialog";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import apiClient from "~/lib/apiClient";
import { useToast } from "~/components/ui/use-toast";
import { Charge } from "~/types/catalog";

const SUPPORTED_CURRENCIES = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
  { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
];

// Define a local interface for the charge form data
interface ChargeFormData {
  id?: string | number;
  name: string;
  amount: string;
  currency: string;
  type: string;
  mode: string;
}

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onChargeCreated: (charge: Charge) => void;
  productId: string;
  initialCharge?: Partial<ChargeFormData>;
};

export default function ChargeDialog({ 
  open, 
  onOpenChange, 
  onChargeCreated, 
  productId,
  initialCharge 
}: Props) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [charge, setCharge] = useState<ChargeFormData>({
    name: initialCharge?.name || '',
    amount: initialCharge?.amount || '',
    currency: initialCharge?.currency || 'USD',
    type: initialCharge?.type || 'standard',
    mode: initialCharge?.mode || 'in_advance',
    ...(initialCharge?.id ? { id: initialCharge.id } : {})
  });

  const handleChange = (field: keyof ChargeFormData, value: string) => {
    setCharge(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (!charge.name) {
      toast({
        title: "Validation Error",
        description: "Charge name is required",
        variant: "destructive"
      });
      return;
    }

    if (!charge.amount) {
      toast({
        title: "Validation Error",
        description: "Amount is required",
        variant: "destructive"
      });
      return;
    }

    // Validate amount is a number
    const amount = parseFloat(charge.amount);
    if (isNaN(amount)) {
      toast({
        title: "Validation Error",
        description: "Amount must be a number",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Convert amount to cents for API
      const amountInCents = Math.round(amount * 100).toString();
      
      // Prepare payload
      const payload = {
        ...charge,
        amount: amountInCents
      };
      
      let response;
      
      if (charge.id) {
        // Update existing charge
        response = await apiClient.put(`/catalog/products/${productId}/charges/${charge.id}`, payload);
      } else {
        // Create new charge
        response = await apiClient.post(`/catalog/products/${productId}/charges`, payload);
      }

      // Call the onChargeCreated callback with the response data
      onChargeCreated(response.data);
      
      // Close the dialog
      onOpenChange(false);
      
      toast({
        title: "Success",
        description: charge.id ? "Charge updated successfully" : "Charge created successfully"
      });
    } catch (error: any) {
      console.error('Error saving charge:', error);
      
      // Handle validation errors
      if (error.response?.status === 422 && error.response?.data?.errors) {
        const validationErrors = error.response.data.errors;
        
        // Display the first validation error
        const firstError = Object.values(validationErrors)[0];
        if (Array.isArray(firstError) && firstError.length > 0) {
          toast({
            title: "Validation Error",
            description: firstError[0],
            variant: "destructive"
          });
        } else {
          toast({
            title: "Validation Error",
            description: "Please check the form for errors",
            variant: "destructive"
          });
        }
      } else {
        toast({
          title: "Error",
          description: "Failed to save charge",
          variant: "destructive"
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{charge.id ? 'Edit Charge' : 'Create New Charge'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              value={charge.name}
              onChange={(e) => handleChange('name', e.target.value)}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="amount" className="text-right">
              Amount
            </Label>
            <Input
              id="amount"
              type="number"
              step="0.01"
              min="0"
              value={charge.amount}
              onChange={(e) => handleChange('amount', e.target.value)}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="currency" className="text-right">
              Currency
            </Label>
            <Select
              value={charge.currency}
              onValueChange={(value) => handleChange('currency', value)}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select currency" />
              </SelectTrigger>
              <SelectContent>
                {SUPPORTED_CURRENCIES.map((currency) => (
                  <SelectItem key={currency.code} value={currency.code}>
                    {currency.symbol} {currency.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="type" className="text-right">
              Type
            </Label>
            <Select
              value={charge.type}
              onValueChange={(value) => handleChange('type', value)}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="standard">Standard</SelectItem>
                <SelectItem value="volume">Volume</SelectItem>
                <SelectItem value="graduated">Graduated</SelectItem>
                <SelectItem value="package">Package</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="mode" className="text-right">
              Mode
            </Label>
            <Select
              value={charge.mode}
              onValueChange={(value) => handleChange('mode', value)}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select mode" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="in_advance">In Advance</SelectItem>
                <SelectItem value="in_arrears">In Arrears</SelectItem>
                <SelectItem value="one_time">One Time</SelectItem>
              </SelectContent>
            </Select>
          </div>
          
          {/* Debug Information */}
          <div className="mt-6 border-t border-gray-200 pt-4">
            <details className="text-sm">
              <summary className="font-medium text-gray-700 cursor-pointer">Debug Information</summary>
              <div className="mt-2 space-y-4">
                <div>
                  <h3 className="font-medium mb-1">Current Charge State:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {JSON.stringify(charge, null, 2)}
                  </pre>
                </div>
                <div>
                  <h3 className="font-medium mb-1">Initial Charge:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {JSON.stringify(initialCharge, null, 2)}
                  </pre>
                </div>
                <div>
                  <h3 className="font-medium mb-1">Product ID:</h3>
                  <pre className="bg-gray-50 p-4 rounded-md overflow-auto max-h-96 text-xs">
                    {productId || 'None'}
                  </pre>
                </div>
              </div>
            </details>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : (charge.id ? 'Update Charge' : 'Create Charge')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 